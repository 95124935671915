<template>
  <div>
      <v-container>

        <div class="row">
            <div class="col-12">
                        <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                YOUR ALIGNER PLAN
                <p style="font-size:10px;color:#949494;margin-bottom:0px;">Here is your teeth will look like
during and when you finished
Freeligner treatment</p>
            </span>
            </div>
        </div>

      <div v-if="treatment.step != treatment.total_treatment">
        <div class="row" v-for="(data, i) in treatment.treatment_detail" :key="i">
          <div class="col-12" v-if="data.step == treatment.step">
            <v-card>
            <v-img
              :src="getImage('images/treatment/' + data.treatment)"
              class="white--text"
              height="300px"
              width="100%"
              >
            </v-img>

            <v-card-actions class="white justify-center">
                <v-btn @click="nextStep" :disabled="button" outlined block color="success">
                  NEXT STEP
                </v-btn>
            </v-card-actions>
                <p style="color:orange;font-size:10px;margin-left:8px;margin-bottom:0px;padding-bottom:5px;">You can clik this button when date is : {{ data.tgl_action | moment("MMMM DD YYYY") }}</p>
          </v-card>

          </div>
          <div class="col-12" v-if="data.step == treatment.total_treatment">
            <v-card>
              <v-img
                :src="getImage('images/treatment/' + data.treatment)"
                class="white--text"
                height="300px"
                width="100%"
                >
              </v-img>
              
            </v-card>

          </div>
        </div>
      </div>
      <div v-if="treatment.step == treatment.total_treatment">
        <div class="row" v-for="(data, i) in treatment.treatment_detail" :key="i">
          <div class="col-12" v-if="data.step == treatment.total_treatment">
            <v-card>
              <v-img
                :src="getImage('images/treatment/' + data.treatment)"
                class="white--text"
                height="300px"
                width="100%"
                >
                <span
                  class="text-h5 white--text pl-4 pt-4 d-inline-block"
                  v-text="'After'"
                ></span>
              </v-img>
            </v-card>

            <br>
            <div v-if="!button">
                <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px; line-height: 16px;"
                >
                  Time is up, Do you wants to new order products ?
                </span>
                <div class="row" style="margin-top:0px;padding-top:0px;">
                  <div class="col-12" style="margin-top:0px;padding-top:0px;">
                    <v-btn class="mt-3" color="primary" large @click="buyProduct">Yes</v-btn>
                    <v-btn class="ml-2 mt-3" large color="error" @click="resetDefault">No</v-btn>
                  </div>
                </div>
            </div>
            <div v-if="button">
                 <div class="form-row form-row-layout" style="background:orange;color:white;border: 1px dashed red;margin-top:5px;padding:5px;">
                                     Your aligner will run out soon!
                </div>
            </div>
          </div>
        </div>
      </div>

    </v-container>

  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'TreatmenPlan',
  data() {
    return {
      treatment: [],
      currentDate: "",
      button: true,
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        carts: 'cart/carts',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        addCart: 'cart/add',
      }),
      resetDefault() {
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/apiv1/reset_to_assessment", {}, config)
          .then(() => {
            this.$router.push("/assessment");
          })
          .catch((error) => {
            console.log(error);
          });
      },
      buyProduct(){
        this.$Progress.start();

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };

        this.axios
          .post("/apiv1/reset_to_product", {}, config)
          .then(() => {
            this.$Progress.finish();
            this.checkProgress();
            this.$router.push("/products");
          })
          .catch((error) => {
            this.$Progress.finish();
            this.$router.push("/payment");
            console.log(error)
          });
      },
      getTreatment(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/treatment", config)
          .then((response) => {
            this.$Progress.finish();
            this.treatment = response.data.data;
            this.currentDateTime()

          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      nextStep(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/apiv1/next_step", {}, config)
          .then((response) => {
            this.$Progress.finish();
            this.treatment = response.data.data;
            this.setAlert({
                      status : true,
                      text  : 'Next step success',
                      color  : 'success',
              })
            window.location.reload();
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      statusProgress() {
        this.$Progress.start()
        let config = {
            headers: {
                'Authorization': 'Bearer ' + this.user.api_token,
            },
        }
        this.axios.post('/apiv1/check_progress', {}, config)
        .then((response) => {
            this.$Progress.finish()
            if (response.data.data.progress == "new") {
              this.$router.push("/assessment") 
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "0"
            ) {
              this.$router.push("/assessment-waiting");
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "1"
            ) {
              this.$router.push("/summary");
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "2"
            ) {
              this.alert = true;
            } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
              this.$router.push("/appointment");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "menunggu"
            ) {
              this.$router.push("/appointment-waiting");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "ditolak"
            ) {
              this.$router.push("/appointment-rejected");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "berhasil"
            ) {
              this.$router.push("/appointment-success");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data == null
            ) {
              this.$router.push("/payment");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "menunggu"
            ) {
              this.$router.push("/payment");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "berhasil"
            ) {
              this.$router.push("/payment-success");
            } else if (
              response.data.data.progress == "transaction" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "menunggu" || 
              response.data.data.progress_data.status == "menunggu_konfirmasi" 
            ) {
              this.$router.push("/transaction");
            } else if (
              response.data.data.progress == "transaction" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "dikonfirmasi"
              || response.data.data.progress_data.status == "dikirim"
            ) {
              this.$router.push("/aligner-plan");
            } else if (
              response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
            ) {
              this.$router.push("/aligner-plan");
            }
        }) 
        .catch((error) => {
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
        })
      },
      currentDateTime() {
        let A = moment();
        this.treatment.treatment_detail.forEach((value) => {
            if(this.treatment.step == value.step){
              if (A.diff(value.tgl_action) >= 0) {
                this.button = false;
              }else{
                this.button = true;
              }
            }
        });
      }
  },
  
  created() {
    this.statusProgress();
    this.getTreatment();
    try {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.$Progress.start();

              let config = {
                headers: {
                  Authorization: "Bearer " + this.user.api_token,
                },
              };

              let formData = {
                token_firebase: token,
              };
              this.axios
                .post("/apiv1/token_firebase", formData, config)
                .then(() => {
                  this.$Progress.finish();
                })
                .catch((error) => {
                  this.$Progress.finish();
                  let { data } = error.response;
                  if (typeof data.message === "string") {
                    this.setAlert({
                      status: true,
                      color: "error",
                      text: data.message,
                    });
                  } else {
                    Object.values(data.message).forEach((value) => {
                      this.setAlert({
                        status: true,
                        color: "error",
                        text: value[0],
                      });
                    });
                  }
                });
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    } catch (e) {
      console.log(e);
    }
  },


};
</script>
