<template>
    <div class="pa-5">
    <v-app-bar app color="white" dark>
      <v-btn to="/profile" icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">EDIT YOUR ACCOUNT</h4>
    </v-app-bar>
        <v-row>
            <v-col
              cols="12" style="margin-top:20%;"
            >
              <div class="text-center">
                  <v-avatar height="200" width="200">
                    <img v-if="user.photo == null && isInitial" :src="require('../../assets/images/profile.png')">
                    <img v-else-if="user.photo != null && isInitial" :src="'https://freedomaligner.com/images/user/' + user.photo">
                    <img v-else-if="isSaving" :src="uploadedFiles">
                  </v-avatar>
                  <div style="height:5px;">
                    <input type="file" @change="filesChange();"
                    accept="image/*" class="inputfile">
                    <h4 style="color:orange;margin-top:10px;">Change photo</h4>
                  </div>


              </div>


               <v-form ref="form" enctype="multipart/form-data" novalidate>

                  <v-text-field
                    style="margin-top:40px;"
                    v-model="name"
                    :rules="nameRules"
                    label="Full Name"
                    outlined
                    dense
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    outlined
                    dense
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    label="Phone number"
                    outlined
                    dense
                    required
                    ></v-text-field>


                    <div class="text-xs-center">


                    <v-btn
                    style="text-transform: capitalize !important;"
                    block
                    x-large
                    class="mt-2 white--text"
                    color="indigo"
                    @click="save"
                    >
                    SAVE
                    </v-btn>
                    </div>
                </v-form>
            </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;


export default {
  name: 'Profile',
   data () {
    return {
        uploadedFiles: null,
        picture: null,
        uploadError: null,
        currentStatus: null,
        name: '',
        nameRules: [
        v => !!v || 'Fullname is required',
        ],
        email: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
        ],
        phone: '',
        phoneRules: [
        v => !!v || 'Phone number is required',
        v => /([0-9_])/.test(v) || 'Phone number must be a number'
        ],
    }
   },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = null;
        this.uploadError = null;
      },
      filesChange() {
        // handle file changes
        this.picture = event.target.files[0];
        this.uploadedFiles = URL.createObjectURL(event.target.files[0]);
        this.currentStatus = STATUS_SAVING;
      },
      save(){
        if (this.$refs.form.validate()) {

          this.$Progress.start();

          let config = {
            headers: {
              Authorization: "Bearer " + this.user.api_token,
              'Content-Type': 'multipart/form-data'
            },
          };

          let formData = new FormData();

          if (this.picture != null) {
            formData.append("name", this.name);
            formData.append("email", this.email);
            formData.append("phone", this.phone);
            formData.append("photo", this.picture);
          }else{
            formData.append("name", this.name);
            formData.append("email", this.email);
            formData.append("phone", this.phone);
          }

          this.axios
          .post("/apiv1/update_account", formData, config)
            .then((response) => {
              this.$Progress.finish();
              let { data } = response.data
              this.setAuth(data)
              this.setAlert({
                status: true,
                color: "success",
                text: response.data.message,
              });
              this.$router.push("/profile");
            })
            .catch((error) => {
              this.$Progress.finish()
              let { data } = error.response
              if(typeof data.message === 'string'){
                this.setAlert({
                      status : true,
                      color  : 'error',
                      text  : data.message,
                })
              }else{
                Object.values(data.message).forEach(value => {

                  this.setAlert({
                      status : true,
                      color  : 'error',
                      text  : value[0],
                  })

                });
              }
            });
        }
    },
  },
  mounted() {
      this.name = this.user.name
      this.email = this.user.email
      this.phone = this.user.phone_number
      this.reset();
  },

};
</script>

<style lang="stylus">

  .inputfile {
    opacity: 0;
    width: 100%;
    position: fixed;
    cursor: pointer;
  }
</style>


