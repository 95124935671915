<template>
  <div>
        
        <div class="row">
            <div class="col-8">
                        <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                History aligner plan
                <p style="font-size:10px;color:#949494;margin-bottom:0px;">Below your history aligner plan</p>
            </span>
            </div>
        </div>

        <v-card flat class="mt-5">
        <v-container class="ma-0 pa-0">

        <v-card
          class="mx-auto mb-3"
          v-for="item in history" :key="item.id" @click="detail(item.id)"
        >
          <v-card-text class="pb-1">
            <div>ORDER ID : {{ item.order_id }}</div>
            <div class="primary--text title">Rp. {{ formatPrice(item.price) }}</div>
            <small>Date: {{ item.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}</small>
          </v-card-text>
          <v-card-actions>
            <span class="ml-2" style="margin-top:-10px;color:orange;font-size:10px;" v-if="item.status == 'menunggu'">
                    Menunggu
                    Pembayaran
                  </span>
                  <span class="ml-2" style="margin-top:-10px;color:#a8ad00;font-size:10px;" v-if="item.status == 'menunggu_konfirmasi'">
                    Menunggu
                    Konfirmasi
                  </span>
                  <span class="ml-2" style="margin-top:-10px;color:#04ced9;font-size:10px;" v-if="item.status == 'dikonfirmasi'">
                    Dikonfirmasi
                  </span>
                  <span class="ml-2" style="margin-top:-10px;color:#a60074;font-size:10px;" v-if="item.status == 'dikirim'">
                    Dikirim
                  </span>
                  <span class="ml-2" style="margin-top:-10px;color:#07a60f;font-size:10px;" v-if="item.status == 'selesai'">
                    Selesai
                  </span>
                  <span class="ml-2" style="margin-top:-10px;color:red;font-size:10px;" v-if="item.status == 'ditolak'">
                    Pembayaran ditolak dengan alasan : {{ item.message }}
                  </span>
          </v-card-actions>
        </v-card>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'HistoryAligner',
  data() {
    return {
      history: [],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      getHistory(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/history_aligner", config)
          .then((response) => {
            this.$Progress.finish();
            this.history = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      detail(id){
        this.$router.push('/history-aligner/'+ id)
      }
  },
  created() {
    this.getHistory()
  },

};
</script>
