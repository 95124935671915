<template>
  <div class="mt-5" v-if="tampil">
    <span
      class="font-weight-black"
      style="color: #2b388f; font-size: 18px; line-height: 16px"
    >
      Let’s start FreedSmile
    </span>
    <br />
    <span class="font-weight-light" style="font-size: 12px">
      Take a clear picture of your teeth
      following the instruction below
    </span>
    <v-divider class="mt-5 mb-5"></v-divider>
    <v-stepper v-model="assessments" vertical outlined tile>
      <v-stepper-step :complete="assessments > 1" step="1" color="#2b388f">
        Front Teeth
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-btn
          color="#2b388f"
          outlined
          rounded
          small
          @click="takePicture('front')"
        >
          <v-icon left> mdi-camera </v-icon>
          Take Picture
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="assessments > 2" step="2">
        Side Teeth
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-btn
          color="#2b388f"
          outlined
          rounded
          small
          @click="takePicture('side')"
        >
          <v-icon left> mdi-camera </v-icon>
          Take Picture
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="assessments > 3" step="3">
        Top Teeth
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-btn
          color="#2b388f"
          outlined
          rounded
          small
          @click="takePicture('top')"
        >
          <v-icon left> mdi-camera </v-icon>
          Take Picture
        </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4"> Bottom Teeth </v-stepper-step>
      <v-stepper-content step="4">
        <v-btn
          color="#2b388f"
          outlined
          rounded
          small
          @click="takePicture('bottom')"
        >
          <v-icon left> mdi-camera </v-icon>
          Take Picture
        </v-btn>
      </v-stepper-content>
    </v-stepper>

    <v-alert
      v-show="alert"
      border="bottom"
      class="mt-2"
      colored-border
      type="error"
      elevation="2"
    >
      Please reupload the pictures. Your
submission was rejected because :

      <label v-html="message_rejected"></label>
    </v-alert>


      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Instruction</v-toolbar-title>

            </v-toolbar>

            <v-card-text style="padding:0px;">
              <img
                :src="require('../../assets/images/tutorial.png')"
                style="height:100%;width:100%;"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/messaging";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Assessment",
  data() {
    return {
      dialog: false,
      tampil: false,
      assessments: 1,
      alert: false,
      message_rejected: null,
    };
  },
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
      guest: "auth/guest",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    takePicture(link) {
      this.$router.push("/camera-" + link);
    },
    messageRejected(){
        this.$Progress.start();
        let url = '/apiv1/assessment_message_rejected';
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get(url, config)
          .then((response) => {
            this.$Progress.finish();
            this.message_rejected = response.data.message;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
            return error.response;
          });
    },
    statusProgress() {
      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "0"
          ) {
            this.$router.push("/assessment-waiting");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "1"
          ) {
            this.$router.push("/summary");
          } else if (
            response.data.data.progress == "new" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "2"
          ) {
            this.alert = true;
            this.tampil = true;
            this.messageRejected()
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/appointment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/appointment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/payment-rejected");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          }else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" || 
            response.data.data.progress_data.status == "ditolak" 
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          } else{
            this.tampil = true;
            this.messageRejected()
          }

      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
  },
  mounted() {
    this.statusProgress();
    if (this.$route.params.next != null) {
      this.assessments = this.$route.params.next;
    }
    if(this.assessments == 1){
        this.dialog = true
    }
  },
  created() {
    try {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.$Progress.start();

              let config = {
                headers: {
                  Authorization: "Bearer " + this.user.api_token,
                },
              };

              let formData = {
                token_firebase: token,
              };
              this.axios
                .post("/apiv1/token_firebase", formData, config)
                .then(() => {
                  this.$Progress.finish();
                })
                .catch((error) => {
                  this.$Progress.finish();
                  let { data } = error.response;
                  if (typeof data.message === "string") {
                    this.setAlert({
                      status: true,
                      color: "error",
                      text: data.message,
                    });
                  } else {
                    Object.values(data.message).forEach((value) => {
                      this.setAlert({
                        status: true,
                        color: "error",
                        text: value[0],
                      });
                    });
                  }
                });
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
