<template>
  <v-container>
    <v-row class="pa-6">
      <v-col cols="12">
        <img
          :src="require('../assets/images/logo.png')"
          class="mt-10"
        />

        <p style="margin-top:60px;font-style: normal;font-weight: 600;font-size: 24px;line-height: 20px;color: #2B388F;letter-spacing: -0.24px;">Forgot your password</p>

        <p style="font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #FAAF40;">
          Use your email to reset your password
        </p>


        <v-form ref="form" lazy-validation>

        <v-text-field
          style="margin-top:60px;"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          dense
          outlined
          required
        ></v-text-field>
        <div class="text-xs-center">

        <v-btn
          style="text-transform: capitalize !important;"
          block
          large
          class="mt-2 white--text"
          color="indigo"
          @click="submit"
        >
          Submit
        </v-btn>
        </div>
      </v-form>

        <p style="margin-top:10px;text-align:center;font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #FAAF40;">
          Please keep your account confidential          
        </p>


      </v-col>
    </v-row>
  </v-container>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../assets/fonts/Poppins-Bold.ttf');
    }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Login',
    data () {
    return {
        email: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
        ],
    }
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      submit () {
        if (this.$refs.form.validate()) {
            this.$Progress.start()
            let formData = {
                'email' : this.email
            }
            this.axios.post('/apiv1/password/forgot', formData)
            .then((response) => {
                this.$Progress.finish()
                  this.setAlert({
                      status : true,
                      color  : 'success',
                      text  : response.data.message,
                  })
            })
            .catch((error) => {
                this.$Progress.finish()
                let { data } = error.response
                this.setAlert({
                    status : true,
                    color  : 'error',
                    text  : data.message,
                })
            })
      }
    },
  },
  created() {
    if(!this.guest){
      this.$router.push({ name: 'Home'})
    }
  },
}
</script>
