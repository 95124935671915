<template>
      <div :class="{ loader: true, fadeout: !isLoading }" style="background:white;">

            <img
              height="70"
              :src="require('../assets/images/logo.svg')"
              style="margin-left:auto;margin-right:auto;display:block; background:white;"
            />
            <img
              height="25"
              :src="require('../assets/images/smileitup.svg')"
              style="margin-left:auto;margin-right:auto;display:block;margin-top:20px;background:white;"
            />

        <img :src="require('../assets/images/welcome.svg')" class="img-bottom" width="100%" style="background:white;" >
      </div>
</template>

<script>
export default {
    name: "LoadingScreen",
    props: ["isLoading"]
}
</script>

<style>
.loader {
  background-color: #63ab97;
  z-index: 1000;
}
.fadeout {
  animation: fadeout 2s forwards;
  display: none;
}
.img-bottom{
    bottom : 0;
    z-index : 87;
    position : fixed;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
