<template>
  <div>
    <v-app-bar app color="white" dark>
      <v-btn to="/products" icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">DETAIL PRODUCT</h4>
    </v-app-bar>


    <div
        class="mt-13 ml-0 mr-0 pr-0"
    >
        <v-img
        :src="getImage(product.image)"
        height="250px"
        ></v-img>

        <v-card-title>
        {{ product.name }}
        </v-card-title>

        <v-card-subtitle>
            {{ product.description }}
        </v-card-subtitle>

        <v-card-actions>
        <v-btn
            color="orange lighten-2"
            text
            style="font-size:20px;"
        >
            Rp {{ formatPrice(product.price) }}
        </v-btn>
         </v-card-actions>
        
    </div>

        <v-card class="bg_cart2 pa-3">
            <v-layout wrap>
              <v-flex xs12>
                <v-btn block large color="success" @click="buy">
                ADD TO CART
                </v-btn>
              </v-flex>
            </v-layout>
        </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Products',
  data() {
    return {
      product: {},
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        carts: 'cart/carts',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        addCart: 'cart/add',
        setCart       : 'cart/set',
      }),
      getProductDetail(){
        this.$Progress.start();
        let { id } = this.$route.params
        let url = '/apiv1/product/'+ id
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get(url, config)
          .then((response) => {
            this.$Progress.finish();
            this.product = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
            return error.response;
          });
      },
      buy(){
          this.addCart(this.product)
          this.setAlert({
                  status : true,
                  text  : 'Added to cart',
                  color  : 'success',
          })
      },
  },
  created() {
    this.getProductDetail();
  },

};
</script>

<style lang="stylus">
  .bg_cart2{
        width : 100%;
        bottom : 0;
        z-index : 87;
        position : fixed;
    }
</style>
