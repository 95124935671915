<template>
  <div class="mt-5">
    <span
      class="font-weight-black"
      style="color: #2b388f; font-size: 18px; line-height: 16px"
    >
      Teeth Health Summary
    </span>
    <br />
    <span class="font-weight-light" style="font-size: 12px">
      Your asessement result
    </span>
    <p style="border: 1px solid #eaeaea; padding:10px;" class="text-justify" v-html="response"></p>

    <span
      class="font-weight-black"
      style="color: #2b388f; font-size: 18px; line-height: 16px"
    >
      Treatment plan
    </span>
    <br />
    <span class="font-weight-light" style="font-size: 12px">
      Detail treatment plan
    </span>
    <p style="border: 1px solid #eaeaea; padding:10px;" class="text-justify" v-html="treatment_plan"></p>

        <span
      class="font-weight-black"
      style="color: #2b388f; font-size: 18px; line-height: 16px"
    >
      Let’s go Dental Trip to finish your
treatment plan
    </span>
    <div class="row">
      <div class="col-12">
        <v-btn class="mt-5" color="primary" large href="/appointment">Yes, Make Appointment</v-btn>
        <v-btn class="ml-2 mt-5" large color="error" @click="rejectTreatment">No</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      response: "",
      treatment_plan: "",
    };
  },
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    getResponse() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/apiv1/assessment-response", config)
        .then(({ data }) => {
          this.response = data.message;
          this.treatment_plan = data.data.treatment_plan;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rejectTreatment() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/apiv1/reject-assessment", {}, config)
        .then(() => {
          this.$router.push("/assessment");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    statusProgress() {
      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
      if (response.data.data.progress == "new") {
        this.$router.push("/assessment");
      } else if (
        response.data.data.progress == "assessment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "0"
      ) {
        this.$router.push("/assessment-waiting");
      } else if (
        response.data.data.progress == "assessment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "2"
      ) {
        this.$router.push("/assessment");
      } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
        this.$router.push("/appointment");
      } else if (
        response.data.data.progress == "appointment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "menunggu"
      ) {
        this.$router.push("/appointment-waiting");
      } else if (
        response.data.data.progress == "appointment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "ditolak"
      ) {
        this.$router.push("/appointment-rejected");
      } else if (
        response.data.data.progress == "appointment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "berhasil"
      ) {
        this.$router.push("/appointment-success");
      } else if (
        response.data.data.progress == "payment" &&
        response.data.data.progress_data == null
      ) {
        this.$router.push("/payment");
      } else if (
        response.data.data.progress == "payment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "menunggu"
      ) {
        this.$router.push("/payment");
      } else if (
        response.data.data.progress == "payment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "berhasil"
      ) {
        this.$router.push("/payment-success");
      } else if (
        response.data.data.progress == "payment" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "ditolak"
      ) {
        this.$router.push("/payment-rejected");
      } else if (
        response.data.data.progress == "product"
      ) {
        this.$router.push("/products");
      } else if (
        response.data.data.progress == "transaction" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "menunggu" || 
        response.data.data.progress_data.status == "menunggu_konfirmasi" 
      ) {
        this.$router.push("/transaction");
      } else if (
        response.data.data.progress == "transaction" &&
        response.data.data.progress_data != null &&
        response.data.data.progress_data.status == "dikonfirmasi"
        || response.data.data.progress_data.status == "dikirim"
      ) {
        this.$router.push("/aligner-plan");
      } else if (
        response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
      ) {
        this.$router.push("/aligner-plan");
      } else if (
        response.data.data.progress == "plan" &&
        response.data.data.progress_data != null
      ) {
        this.$router.push("/treatment-plan");
      }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
  },
  mounted() {
    this.statusProgress();
    this.getResponse();
  },
};
</script>
