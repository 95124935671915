import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import './plugins/helper'
import firebase from "firebase/app";
import "firebase/messaging";
import EasyCamera from 'easy-vue-camera';
import VueMeta from 'vue-meta'

firebase.initializeApp({
  apiKey: "AIzaSyDyGpjida7JPBhPVPkNs30DGwErBXIFhY4",
  authDomain: "dental-45762.firebaseapp.com",
  projectId: "dental-45762",
  storageBucket: "dental-45762.appspot.com",
  messagingSenderId: "451435912",
  appId: "1:451435912:web:d05c4dfffb8f270c88ee3d",
  measurementId: "G-5NPQJVJT1F"
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });

Vue.config.productionTip = false


Vue.use(EasyCamera);
Vue.use(VueMeta)

const options = {
  color: '#2B388F',
  failedColor: '#874b4b',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}


Vue.use(VueProgressBar, options)

Vue.use(require('vue-moment'));


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
