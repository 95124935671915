<template>
  <div>
    <div class="d-flex flex-column justify-space-between align-center pa-10">
      <v-row align="stretch">
        <v-col cols="12" class="d-flex flex-column align-center pt-5">
          <img v-if="new_progress_data.is_treatment == 'y'"
            style="padding-top: 20px;"
            :src="require('../../assets/images/success.svg')"
            class="bg-center"
          />

          <img v-if="new_progress_data.is_treatment == 'n'"
            style="padding-top: 20px;"
            :src="require('../../assets/images/rejected.svg')"
            class="bg-center"
          />
          <br />
          <p class="txt-header" style="margin-top:20px;font-size: 20px;color:orange;">Thank You!</p>
          <p class="txt-body" style="font-size: 15px;color: #2B388F;text-align:center;">
          Your payment has been
succesfully received
          </p>
        </v-col>

        <v-col>
          <div v-if="new_progress_data.is_treatment == 'y'" class="text-center mt-5">
            <v-btn
            large
              style="text-transform: capitalize !important; margin-top: -20px"
              class="white--text"
              color="indigo"
              @click="buyProducts"
            >
               <v-icon left>mdi-shopping</v-icon> &nbsp;&nbsp; &nbsp;  Get your Aligner
            </v-btn>
          </div> 
          <div v-if="new_progress_data.is_treatment == 'n'" class="text-center mt-5">
            <p style="text-aligner:center;">Mohon maaf, keadaan gigimu saat
                ini tidak memungkinkan untuk
                menggunakan aligner karena : <span v-html="new_progress_data.treatment_message"></span></p>
            <v-btn class="ml-2 mt-5" large color="success" @click="rejectTreatment">Back To Home</v-btn>

          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppTemplate",
  data: () => ({
      new_progress_data : null,
  }),
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    rejectTreatment() {
      console.log(this.user.api_token);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/apiv1/reject-assessment", {}, config)
        .then(() => {
          this.$router.push("/assessment");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    statusProgress() {

      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
      }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          this.new_progress_data = response.data.data.progress_data;
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "0"
          ) {
            this.$router.push("/assessment-waiting");
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" 
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
          ) {
            this.$router.push("/aligner-plan");
          }  else if (
            response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })

     
    },
    buyProducts(){
      this.$Progress.start();

      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };

      this.axios
        .post("/apiv1/check-products", {}, config)
        .then(() => {
          this.$Progress.finish();
          this.checkProgress();
          this.$router.push("/products");
        })
        .catch((error) => {
          this.$Progress.finish();
          this.$router.push("/payment");
          console.log(error)
        });
    }

  },
  created() {
    this.statusProgress();
  },
};
</script>


<style lang="stylus">
.bg-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -40px;
}

@font-face {
    font-family: Montserrat-bold;
    src: url('/fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: Montserrat;
    src: url('/fonts/Montserrat-Regular.ttf');
}

.txt-header{
  font-family: 'Montserrat-bold';
}

.txt-body{
  font-family: 'Montserrat';
}
</style>
