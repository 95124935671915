<template>
  <v-container>
    <v-row class="pa-6">
      <v-col cols="12">
        <img
          :src="require('../assets/images/logo.svg')"
          style="margin-top:-80px;"
        />

        <div style="margin-top:-100px;">

       
        <p style="margin-top:60px;font-style: normal;font-weight: 600;font-size: 24px;line-height: 20px;color: #2B388F;letter-spacing: -0.24px;">Let's sign you in</p>

        <p style="font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #FAAF40;">
          Welcome back, and enjoy with us
        </p>


        <v-form ref="form" lazy-validation>

        <v-text-field
          style="margin-top:60px;"
          v-model="email"
          :rules="emailRules"
          label="Email"
          outlined
          dense
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          dense
          hint="At least 6 characters"
          counter
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <div class="text-xs-center">


        <p style="margin-top:50px;text-align:center;font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #2B388F;">
          Do you have an account ? <router-link style="color: #FAAF40;text-decoration:none;" to="/register">Sign Up</router-link> <br> Or <router-link style="font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;color: #FAAF40;text-decoration:none;" to="/forgot_password">Forgot Password ?</router-link>

        </p>

        <v-btn
          style="text-transform: capitalize !important;"
          block
          large
          class="mt-2 white--text"
          color="indigo"
          @click="submit"
        >
          Sign In
        </v-btn>
        </div>
      </v-form>

      </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../assets/fonts/Poppins-Bold.ttf');
    }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Login',
    data () {
    return {
        email: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
        ],
        showPassword: false,
        password: '',
        passwordRules: [
        v => !!v || 'Password required.',
        v => (v && v.length >= 6) || 'Min 6 characters',
        ],
    }
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      submit () {
        if (this.$refs.form.validate()) {
            this.$Progress.start()
            let formData = {
                'email' : this.email,
                'password' : this.password
            }
            this.axios.post('/apiv1/login', formData)
            .then((response) => {
                this.$Progress.finish()
                let { data } = response.data
                this.setAuth(data)
                if(this.user.id>0){
                    this.setAlert({
                        status : true,
                        color  : 'success',
                        text  : response.data.message,
                    })
                    this.$router.push({ name: 'Home'})
                }
                else{
                    this.setAlert({
                        status : true,
                        color  : 'error',
                        text  : response.data.message,
                    })
                }
            })
            .catch((error) => {
                this.$Progress.finish()
                let { data } = error.response
                this.setAlert({
                    status : true,
                    color  : 'error',
                    text  : data.message,
                })
            })
      }
    },
  },
  created() {
    if(!this.guest){
      this.$router.push({ name: 'Home'})
    }
  },
}
</script>
