<template>
  <div>
    <v-toolbar dark color="primary">
        <v-btn icon dark to="/products">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Your Shopping Cart!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid>
        <div v-if="countCart===0">
            <v-alert
                outlined
                color="warning"
                icon="mdi-cart-off"
            >
                Keranjang belanja kosong!
            </v-alert>
        </div>
        <v-list three-line v-if="countCart>0">
            <template v-for="(item, index) in carts">
                <v-list-item style="border: 2px solid #eaeaea;margin-top:5px;"
                :key="'cart'+index"
                >
                <v-list-item-avatar>
                    <v-img :src="getImage(item.image)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle>
                        Rp {{ formatPrice(item.price) }}
                        <span style="float:right">
                        <v-btn icon small rounded depressed @click.stop="removeCart(item)">
                            <v-icon dark color="error">mdi-minus-circle</v-icon>
                        </v-btn>
                        {{ item.quantity }}
                        <v-btn icon small rounded depressed @click.stop="addCart(item)">
                            <v-icon dark color="success">mdi-plus-circle</v-icon>
                        </v-btn>
                        </span> 
                    </v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </template>                
        </v-list>
        <v-row>
            <v-card class="bg_cart3" style="background:rgb(43, 56, 143);">
                <v-card-text>
                    <v-layout wrap>
                        <v-flex xs6 style="color:white;">
                            Total Price ({{ totalQuantity }} items)<br>
                            <span class="title">Rp. {{ totalPrice.toLocaleString('id-ID') }}</span>
                        </v-flex>
                        <v-flex class="mt-2" xs6 text-right>
                            <v-btn color="light" @click="checkout" :disabled="totalQuantity==0">
                            <v-icon>mdi-cart-arrow-right</v-icon> &nbsp;
                                Checkout 
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'cart',
    computed: {
      ...mapGetters({
        carts   : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
      }),
    },
    methods: {
        ...mapActions({
          setAlert    : 'alert/set',
          addCart     : 'cart/add',
          removeCart  : 'cart/remove',
          setCart     : 'cart/set',
        }),
        checkout(){
            this.close()
            this.$router.push({path: "/checkout"})
        },
        close() {
            this.$emit('closed', false)
        }
    },
    
}
</script>

<style lang="stylus">
  .bg_cart3{
        width : 100%;
        bottom : 0;
        z-index : 87;
        position : fixed;
    }
</style>