"use strict"
import Vue from 'vue'
import store from '../store'

const Helper = {
    install(Vue) {
        Vue.prototype.appName = process.env.VUE_APP_NAME

        Vue.prototype.checkProgress = function (){
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + store.getters['auth/user'].api_token,
                },
            }
            
            let result = []

            this.axios.post('/apiv1/check_progress', {}, config)
            .then((response) => {
                store.commit('check_progress/set', {
                    progress : response.data.data.progress,
                    progress_data  : response.data.data.progress_data,
                })
            }) 
            .catch((error) => {
                if(error.response.data.message == "Unauthenticate"){
                    store.dispatch('auth/set', {})
                    store.dispatch('alert/set', {
                        status : true,
                        text  : 'Silahkan Login terlebih dahulu',
                        color  : 'error',
                      })
                
                      this.$router.push('/login')
                }
            })

            return result
        },

        Vue.prototype.getImage = function (image){
        if(image!=null && image.length>0){
            return process.env.VUE_APP_BACKEND_URL + image
        }
            return "/../assets/images/profile.png"
        }
           
        Vue.prototype.formatPrice = function (value){
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }


           
    }
}

Vue.use(Helper)