<template>
    <div>
          <v-list-item class="ma-0 pl-1">
            <v-list-item-content>
              <v-list-item-title style="font-size:15px;">List products</v-list-item-title>
            </v-list-item-content>

            <!-- <v-row
              class="pr-1"
              align="center"
              justify="end" style="font-size:15px;color:rgb(43, 56, 143);font-weight:bold;"
            >
              <v-icon class="mr-1">
                mdi-history
              </v-icon>
              <router-link to="/transaction" style="font-size:15px;color:rgb(43, 56, 143);font-weight:bold;text-decoration: none;" class="subheading">History Transaction</router-link>
            </v-row> -->
        </v-list-item>

        <v-row class="ma-0 mt-2">
          <v-flex v-for="(data, i) in product" :key="i" xs6>
            <v-card class="ma-1" :to="'/product/'+ data.id">
              <v-img
              :src="getImage(data.image)"
              class="white--text"
              height="150px"
              >
              </v-img>
               <v-card-title class="pl-2">
                  {{ data.name }}
                </v-card-title>
                <v-card-actions class="mb-0 pb-0">
                  <p class="orange--text"> Rp {{ formatPrice(data.price) }}
                  </p>
                </v-card-actions>
            </v-card>
          </v-flex>
      </v-row>

      <v-row>
          <v-card class="bg_cart pa-3" style="background:rgb(43, 56, 143);">
            <v-layout>
              <v-flex pa-1 pt-3 style="color:white;">
                My Cart ( <span style="font-weight:bold;color:orange;">{{ this.countCart }} Items</span> )<br>
              </v-flex>
              <v-flex pa-1 text-right>
                <v-btn color="light" to="/cart">
                <v-icon>mdi-cart-arrow-right</v-icon> &nbsp;
                Show
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
      </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Products',
  data() {
    return {
      product: [],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        countCart : 'cart/count',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        setCart       : 'cart/set',
      }),
      getProduct(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/list_product", config)
          .then((response) => {
            this.$Progress.finish();
            this.product = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      statusProgress() {
      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment") 
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "0"
          ) {
            this.$router.push("/assessment-waiting");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "1"
          ) {
            this.$router.push("/summary");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "2"
          ) {
            this.alert = true;
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/appointment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/appointment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/payment-rejected");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" || 
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/aligner-plan");
          }  else if (
            response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
  },
  created() {
    this.getProduct();
    this.statusProgress();
  },

};
</script>


<style lang="stylus">
  .bg_cart{
        width : 100%;
        bottom : 0;
        z-index : 87;
        position : fixed;
    }
</style>
