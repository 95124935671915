<template>
      <v-row>
            <v-col cols="12" sm="12" style="margin-top:10px;">
              <div class="mt-0">
                <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px; line-height: 16px"
                >
                Profile Account
                </span>
                <br />
              </div>
            </v-col>
            <v-col
              cols="12" style="margin-top:-10px;"
            >
              <div class="text-center">
                  <v-avatar height="200" width="200">
                    <img v-if="user.photo == null " :src="require('../assets/images/profile.png')">
                    <img v-else :src="'https://freedomaligner.com/images/user/' + user.photo">
                  </v-avatar>
                  <h4 style="margin-top:10px;">{{ user.name }}</h4>
                  <p style="font-size:10px;">{{ user.email }}</p>
              </div>
            </v-col>

            <v-col cols="12" style="margin-top:-10px;"
            >
              <v-card
                
                >
                  <v-list-item @click="editAccount()">
                    <v-list-item-avatar>
                      <v-icon small color="warning">mdi-account-edit-outline</v-icon>
                    </v-list-item-avatar>


                    <v-list-item-content style="margin-top:10px;">
                    <v-list-item-title>Edit Profile</v-list-item-title>
                    <p style="font-size:10px;color:#9e9e9e;">Tap here to edit your account</p>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12"
            >
              <v-card
                >
                  <v-list-item @click="changePassword()">
                    <v-list-item-avatar>
                      <v-icon small color="red">mdi-key</v-icon>
                    </v-list-item-avatar>


                    <v-list-item-content style="margin-top:10px;">
                    <v-list-item-title>Change Password</v-list-item-title>
                    <p style="font-size:10px;color:#9e9e9e;">Tap here to change your password account</p>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
            </v-col>
      </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Profile',
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      editAccount() {
        this.$router.push("/edit-account");
      },
      changePassword() {
        this.$router.push("/change-password");
      },
  },

};
</script>

