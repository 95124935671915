<template>
  <div class="mt-5" style="margin-top: 100%">
    <v-container class="text-center mt-5">
      <v-img
        src="../../assets/images/assessment-waiting.svg"
        class="mt-3"
      >
      </v-img>
      <p class="txt-header" style="margin-top:20px;font-size: 20px;color:orange;">THANK YOU.</p>
      <p class="txt-body" style="font-size: 15px;color: #2B388F;">
       We have received your picture and
        assessing it right now. We will send
        the result to you soon.
      </p>

      <img
              height="10"
              :src="require('../../assets/images/smileitup.svg')"
              style="margin-left:auto;margin-right:auto;display:block;margin-top:40px;"
            />
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    statusProgress() {
      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "2"
          ) {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "1"
          ) {
            this.$router.push("/summary");
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/appointment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/appointment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/payment-rejected");
          } else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" 
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
  },
  mounted() {
    this.statusProgress();
  },
};
</script>


<style lang="stylus">
    @font-face {
        font-family: Montserrat-bold;
        src: url('/fonts/Montserrat-Bold.ttf');
    }

    @font-face {
        font-family: Montserrat;
        src: url('/fonts/Montserrat-Regular.ttf');
    }

    .txt-header{
      font-family: 'Montserrat-bold';
    }

    .txt-body{
      font-family: 'Montserrat';
    }
</style>