<template>
  <div>
        <v-list-item class="ma-0 pl-1">
            <v-list-item-content>
              <v-list-item-title style="font-size:15px;">List products</v-list-item-title>
            </v-list-item-content>

            <v-row
              class="pr-1"
              align="center"
              justify="end" style="font-size:15px;color:rgb(43, 56, 143);font-weight:bold;"
            >
              <v-icon class="mr-1">
                mdi-history
              </v-icon>
              <router-link to="/transaction" style="font-size:15px;color:rgb(43, 56, 143);font-weight:bold;text-decoration: none;" class="subheading">History Transaction</router-link>
            </v-row>
        </v-list-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PaymentOrder',
  data() {
    return {
      transaction: [],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
  },

};
</script>
