<template>
  <div>
    <div class="mt-5">
      <v-form ref="form" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <div class="mt-0">
                <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px; line-height: 16px"
                >
                  Create an Appointment
                </span>
                <br />
                <span class="font-weight-light" style="font-size: 12px">
                  Please! select your time and clinic
                </span>
              </div>
            </v-col>

            <v-col cols="6" sm="6">
              <v-dialog
                ref="dialog2"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="date"
                    label="Select Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :rules="dateRules"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" :min="current_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="6" sm="6">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="time"
                    label="Select Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="timeRules"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="time" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(time)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col style="margin-top: -20px" cols="12" sm="12">
              <v-select
                :items="clinic"
                :rules="clinicRules"
                :hint="`Lokasi : ${select.address}`"
                label="Select Clinic"
                item-text="name"
                item-value="id"
                v-model="select"
                persistent-hint
                return-object
                outlined
              ></v-select>
            </v-col>

            <v-col>
              <v-btn
                style="text-transform: capitalize !important; margin-top: -20px"
                block
                x-large
                class="white--text"
                color="indigo"
                @click="submit"
              >
                Save Appointment
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment'

export default {
  name: "AppTemplate",
  data: () => ({
    select: { id: '', name: '', address: '-', phone: '', created_at: '', updated_at: '' },
    clinic: [],
    clinic_id: null,
    time: null,
    timeRules: [(v) => !!v || "Time is required"],
    clinicRules: [(v) => !!v || "Clinic is required"],
    dateRules: [(v) => !!v || "Date is required"],
    activePicker: null,
    date: null,
    modal: false,
    modal2: false,
    current_date: null,
  }),
  watch: {
    modal(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAuth: "auth/set",
      setAlert: "alert/set",
    }),
    save(date) {
      this.$refs.modal.save(date);
    },
    getClinic() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/apiv1/list_clinic", config)
        .then((response) => {
          this.clinic = response.data.data;
        })
        .catch((error) => {
          return error.response;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$Progress.start();

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };

        let formData = {
          tanggal: this.date,
          waktu: this.time,
          clinic_id: this.select.id,
        };
        this.axios
          .post("/apiv1/appointment", formData, config)
          .then((response) => {
            this.$Progress.finish();
            this.setAlert({
              status: true,
              color: "success",
              text: response.data.message,
            });
            this.checkProgress();
            this.$router.push("/appointment-waiting");
          })
          .catch((error) => {
            this.$Progress.finish();
            let { data } = error.response;
            if (typeof data.message === "string") {
              this.setAlert({
                status: true,
                color: "error",
                text: data.message,
              });
            } else {
              Object.values(data.message).forEach((value) => {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: value[0],
                });
              });
            }
          });
      }
    },
    statusProgress() {

      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "0"
          ) {
            this.$router.push("/assessment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/appointment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/appointment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/payment-rejected");
          } else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" || 
            response.data.data.progress_data.status == "ditolak"  
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
    currentDate() {
        let a = moment().format("YYYY-MM-DD");
        this.current_date = a;
      }
  },
  created() {
    this.currentDate();
    this.statusProgress();
    this.getClinic();
  },
};
</script>


<style lang="stylus">
  .v-date-picker-table table tr td{
    border:none;
    border-style: none;
  }
  .v-date-picker-table th{
    border:none;
    border-style: none;
  }
  .v-date-picker-table table{
    border:none;
    border-style: none;
  }
</style>