<template>
  <div>
    <div class="mt-5">
      <v-row>
            <v-col cols="12" sm="12">
              <div class="mt-0">
                <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px; line-height: 16px"
                >
                  Invoice
                </span>
                <br />
                <span class="font-weight-light" style="font-size: 12px">
                  Please finish payment before due date
                </span>
              </div>
            </v-col>


            <v-col v-if="invoice != null" >

                <div v-show="new_progress_data.proof_payout != null && new_progress_data.status == 'ditolak'">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="error"
                    elevation="2"
                  >
                    Sorry, your proof payout has rejected by admin. please reupload your proof payout.
                  </v-alert>
                </div>
                       

                <v-card
                    class="mx-auto pa-4"
                    outlined
                >
                    <v-row no-gutters>
                        <v-col
                            :cols="6"
                        >
                            <p class="text-left" >Invoice {{ user.name }}</p>
                        </v-col>
                        <v-col
                            :cols="6"
                        >
                            <p style="color: #2b388f; font-size: 18px;font-weight:bold;" class="text-right" >#{{ invoice.id }}</p>
                        </v-col>

                         <p style="font-size:10px;">Tagihan appoinment tanggal {{ invoice.appoinment.tanggal }} di {{ invoice.appoinment.clinic.name }}</p>
                         <p style="font-size:10px;margin-top:0px;margin-bottom:2px;" v-html="'<b>Keterangan</b> ' + invoice.message"></p>

                        </v-row>
                        
                         <p>Total yang harus di bayar</p>
                         <p style="color: #2b388f; font-size: 25px;font-weight:bold;margin-top:-10px;margin-bottom:0px;" class="text-left">Rp. {{ invoice.price }}</p>
                         <span style="color:white;background:red;padding:2px;font-size:10px;margin-top:5px;border-radius:2px;">Due date : {{ invoice.due_date | moment("D MMMM YYYY") }}</span>
                </v-card>
                        <br>
                    <div v-show="new_progress_data.proof_payout == null || new_progress_data.status == 'ditolak'">
                      <div>
                          <span
                              class="font-weight-black"
                              style="color: #2b388f; font-size: 18px;margin-top:-10px;"
                          >
                              Payment Account
                              <p class="font-weight-light" style="font-size: 12px;color: rgba(0, 0, 0, 0.87);">
                                Transfer to one of the following account
                              </p>
                          </span>
                      </div>
                      <v-card flat>
                      <v-container style="padding-top:0px;">
                        <table style="border:none;border-style:none;">
                            <tr style="border:none;border-style:none;">
                                <td style="border:none;border-style:none;"><img :src="require('../../assets/images/bca.png')"></td>
                                <td style="border:none;border-style:none;padding-left:10px;">
                                <span
                                class="font-weight-black"
                                style="font-size: 18px;"
                            >
                                5085148718
                                <p style="font-size:10px;color:#949494;">Name of Bank Acccount <br>K LAKSITA PRATIWI A</p>
                            </span>
                                </td>
                            </tr>
                        </table>
                      </v-container>
                      </v-card>

                    </div>

                       <form v-show="new_progress_data.proof_payout == null || new_progress_data.status == 'ditolak'" enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                          <span
                            class="font-weight-black"
                            style="color: #2b388f; font-size: 18px; line-height: 16px;"
                          >
                            Upload proof of payment
                          </span>
                          <br />
                          <div class="dropbox">
                            <input type="file" @change="filesChange();"
                              accept="image/*" class="input-file">
                              <p v-if="isInitial">
                                Select Your file <br> or click to browse
                              </p>
                              <p v-if="isSaving">
                                <img v-bind:src="uploadedFiles" style="width:100%;">
                              </p>
                          </div>

                          <div class="text-center mt-5">
                            <v-btn
                              large
                              block
                              style="text-transform: capitalize !important; margin-top: -20px"
                              class="white--text mt-5"
                              color="indigo"
                              @click="submit"
                            >
                              Upload
                            </v-btn>
                          </div>
                        </form>

                        <div v-show="new_progress_data.proof_payout != null && new_progress_data.status == 'menunggu'">
                          <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                          >
                            Your proof of payment is process until verified by admin.
                          </v-alert>
                        </div>
            </v-col>

            <v-col v-else cols="12" class="d-flex flex-column align-center pt-10">
                <img
                    style="padding-top: 120px"
                    :src="require('../../assets/images/waiting.png')"
                    class="bg-center"
                />
                <br />
                <span class="font-weight-light text-center" style="font-size: 12px">
                    Thank you, your invoice is process creating by admin.
                </span>
            </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: "AppTemplate",
  data: () => ({
      invoice: null,
      uploadedFiles: null,
      uploadError: null,
      currentStatus: null,
      new_progress_data : null,
      formFields: {
          picture: null
      },
  }),
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = null;
        this.uploadError = null;
    },
    statusProgress() {

      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          this.new_progress_data = response.data.data.progress_data;
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "0"
          ) {
            this.$router.push("/assessment-waiting");
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/payment-rejected");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          } else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" 
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "dikonfirmasi"
            || response.data.data.progress_data.status == "dikirim"
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
          ) {
            this.$router.push("/aligner-plan");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })

    },
    submit(){
      if (this.formFields.picture != null) {
        this.$Progress.start();

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };

        let formData = new FormData();

        formData.append("picture", this.formFields.picture);

        this.axios
          .post("/apiv1/payment-process", formData, config)
          .then((response) => {
            this.$Progress.finish();
            this.setAlert({
              status: true,
              color: "success",
              text: response.data.message,
            });
            window.location.reload()
            this.checkProgress();
          })
          .catch((error) => {
            this.$Progress.finish();
            let { data } = error.response;
            if (typeof data.message === "string") {
              this.setAlert({
                status: true,
                color: "error",
                text: data.message,
              });
            } else {
              Object.values(data.message).forEach((value) => {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: value[0],
                });
              });
            }
          });
      }else{
            this.setAlert({ 
                status: true,
                color: "error",
                text: "File tidak boleh kosong",
            });
      }
    },
    getInvoice(){
      this.$Progress.start();

      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };

      this.axios
        .post("/apiv1/check-invoice", {}, config)
        .then((response) => {
          this.$Progress.finish();
          this.invoice = response.data.data
        })
        .catch((error) => {
          this.$Progress.finish();
          console.log(error)
        });
    },
    filesChange() {
        // handle file changes
        this.formFields.picture = event.target.files[0];
        this.uploadedFiles = URL.createObjectURL(event.target.files[0]);
        this.currentStatus = STATUS_SAVING;
      }
  },
  created() {
    this.statusProgress();
    this.getInvoice();
    this.reset();
  },
};
</script>


<style lang="stylus">
.bg-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -40px;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: white;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  table {
    font-family: sans-serif;
    color: #232323;
    border-collapse: collapse;
  }
 
  table, th, td {
      border: 1px solid #999;
      padding: 2px;
  }
</style>
