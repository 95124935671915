<template>
  <v-container>
    <v-row class="pa-6">
      <v-col cols="12">
        <img
          :src="require('../assets/images/logo.svg')"
          style="margin-top:-80px;"
        />

        <div style="margin-top:-120px;">

        <p style="margin-top:60px;font-style: normal;font-weight: 600;font-size: 24px;line-height: 20px;color: #2B388F;letter-spacing: -0.24px;">Let's create your account</p>

        <p style="font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #FAAF40;">
          Sign up to get started
        </p>


        <v-form ref="form" lazy-validation style="margin-top:-30px;">
        <v-text-field
          style="margin-top:60px;"
          v-model="name"
          :rules="nameRules"
          :counter="255"
          label="Full Name"
          outlined
          dense
          required
        ></v-text-field>

        <v-text-field
          v-model="phone_number"
          :rules="phoneNumberRules"
          label="Phone number"
          outlined
          dense
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          dense
          outlined
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          dense
          hint="At least 6 characters"
          counter
          @click:append="showPassword = !showPassword"
        ></v-text-field>

          <v-text-field
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="confirmPassword"
          :rules="confirmPasswordRules"
          label="Confirm Password"
          :type="showPassword2 ? 'text' : 'password'"
          outlined
          dense
          hint="At least 6 characters"
          counter
          @click:append="showPassword2 = !showPassword2"
        ></v-text-field>

        <div class="text-xs-center">


        <p style="text-align:center;font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #2B388F;">
          Already have an account ? <router-link style="color: #FAAF40;text-decoration:none;" to="/login">Sign In</router-link>
        </p>

        <v-btn
          style="text-transform: capitalize !important;"
          block
          large
          class="mt-2 white--text"
          color="indigo"
          @click="submit"
        >
          Sign Up
        </v-btn>
        </div>
      </v-form>

        <p style="margin-top:10px;text-align:center;font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px;letter-spacing: -0.24px;color: #2B388F;">
          By creating an account, you agree to the <span style="color:#FAAF40;">Terms and Conditions</span> and <span style="color:#FAAF40;">Privacy Policy</span>
          </p>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../assets/fonts/Poppins-Bold.ttf');
    }
</style>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'Login',
    data () {
    return {
        name: '',
        nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 255) || 'Name must be less than 255 characters'
        ],
        email: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
        ],
        phone_number: '',
        phoneNumberRules: [
        v => !!v || 'Phone number is required',
        v => Number.isInteger(Number(v)) || "The value must be an number ex: 085230xxxxxx"
        ],
        showPassword: false,
        showPassword2: false,
        password: '',
        passwordRules: [
        v => !!v || 'Password required.',
        v => (v && v.length >= 6) || 'Min 6 characters',
        ],
        confirmPassword: '',
        confirmPasswordRules: [
          (value) => !!value || 'type confirm password',
          (value) =>
            value === this.password || 'The password confirmation does not match.',
        ],
    }
  },
  methods: {
      ...mapActions({
        setAlert : 'alert/set',
      }),
      submit () {
        if (this.$refs.form.validate()) {
            this.$Progress.start()
            let formData = {
                'name' : this.name,
                'email' : this.email,
                'phone_number' : this.phone_number,
                'password' : this.password
            }
            this.axios.post('/apiv1/register', formData)
            .then((response) => {
                this.$Progress.finish()
                this.setAlert({
                    status : true,
                    color  : 'success',
                    text  : response.data.message,
                })
                this.$router.push({ name: 'Login'})
            })
            .catch((error) => {
                this.$Progress.finish()
                let { data } = error.response
                if(typeof data.message === 'string'){
                  this.setAlert({
                        status : true,
                        color  : 'error',
                        text  : data.message,
                  })
                }else{
                  Object.values(data.message).forEach(value => {

                    this.setAlert({
                        status : true,
                        color  : 'error',
                        text  : value[0],
                    })

                  });
                }

            })
      }
    },
  },
}
</script>
