<template>
  <div class="mt-5" style="margin-top: 100%">
    <v-container>
      <v-img v-if="pd.status != 'dikirim'"
        src="../../assets/images/waiting2.svg"
        width="200"
        style="margin-left:auto;margin-right:auto;display:block;"
      >
      </v-img>
      <p v-if="pd.status != 'dikirim'" class="txt-header" style="margin-top:20px;font-size: 20px;color:orange;text-align:center;">THANK YOU.</p>
      <p v-if="pd.status != 'dikirim'" class="txt-body" style="font-size: 15px;color: #2B388F;text-align:center;">
       Your Freeligner is in the making by
        Dr. Freed! Please wait for 5-10 days
        while we send your Freeligner to
        you. Time difference vary according
        to shipment address.
      </p>

      <v-img v-if="pd.status == 'dikirim'"
        src="../../assets/images/received.svg"
        width="200"
        style="margin-left:auto;margin-right:auto;display:block;"
      >
      </v-img>
      <p v-if="pd.status == 'dikirim'" class="txt-header" style="margin-top:20px;font-size: 20px;color:orange;text-align:center;">YEAY!</p>
      <p v-if="pd.status == 'dikirim'" class="txt-body" style="font-size: 15px;color: #2B388F;text-align:center;">
       We have sent your Freeligner
package to your home! Please check
shipping information below
      </p>

        <div v-if="pd.status == 'dikirim'">

          <hr>

          <div class="row">
            <div class="col-12 mt-2">
                        <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                Your order is being shipped
            </span>
            <p style="font-size:10px;color:#949494;margin-bottom:0px;">Please wait until your order arrives</p>
          </div>
        </div>
          
          <div class="row" v-if="pd.ekspedisi == 'jnt'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/jnt.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'jne'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/jne.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'pos'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/pos.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'tiki'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/tiki.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'sicepat'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/sicepat.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'pahala'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/pahala.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'wahana'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/wahana.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="pd.ekspedisi == 'indah_logistik'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/indah_logistik.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          
            <div class="form-row form-row-layout" style="border: 1px dashed black;margin-top:5px;padding:5px;">
                <span>RESI : </span>
                <input disabled type="text" v-model="pd.no_resi" ref="noresi" />
                <v-btn @click="copy" text style="padding:0px;min-width:0px;">
                  <v-icon
                  >
                    mdi-content-copy
                  </v-icon>
                </v-btn>
          </div>
        </div>

        <div class="mt-4" v-if="pd.status == 'dikirim'">
            <p style="font-size:14px;margin-bottom:1px;margin-top:5px;">If you have received your Freeligner,
please confirm by tapping the button
below</p>
            <v-btn class="mt-2" @click="dialogConfirm3=true" block large color="warning">
                ORDER HAS BEEN RECEIVED
            </v-btn>
        </div>
        
    </v-container>


    <template>
        <v-layout row justify-center>

            <v-dialog v-model="dialogConfirm3" persistent max-width="290">
              <v-card>
                  <v-card-title>Confirmation ?</v-card-title>
                  <p class="ml-5">Has your order been received ?</p>
                  <v-card-actions>
                      <v-btn color="warning" @click="cancelOrder">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="checkPlan"> Yes</v-btn>
                  </v-card-actions>
              </v-card>
            </v-dialog>
        </v-layout>
    </template>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/messaging";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      pd:[],
      dialogConfirm3: false,
    };
  },
  computed: {
    ...mapGetters({
      progress: "check_progress/progress",
      progress_data: "check_progress/progress_data",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth : 'auth/set',
    }),
    copy(){
        navigator.clipboard.writeText(this.pd.no_resi);
        document.execCommand('copy');
        this.setAlert({
          status: true,
          color: "success",
          text: "Copied",
        });
    },
    statusProgress() {
      this.$Progress.start()
      let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
            }
            
      this.axios.post('/apiv1/check_progress', {}, config)
      .then((response) => {
          this.$Progress.finish()
          if (response.data.data.progress == "new") {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "2"
          ) {
            this.$router.push("/assessment");
          } else if (
            response.data.data.progress == "assessment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "1"
          ) {
            this.$router.push("/summary");
          } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
            this.$router.push("/appointment");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/appointment-waiting");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "ditolak"
          ) {
            this.$router.push("/appointment-rejected");
          } else if (
            response.data.data.progress == "appointment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/appointment-success");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data == null
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu"
          ) {
            this.$router.push("/payment");
          } else if (
            response.data.data.progress == "payment" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "berhasil"
          ) {
            this.$router.push("/payment-success");
          } else if (
            response.data.data.progress == "product"
          ) {
            this.$router.push("/products");
          } else if (
            response.data.data.progress == "transaction" &&
            response.data.data.progress_data != null &&
            response.data.data.progress_data.status == "menunggu" || 
            response.data.data.progress_data.status == "menunggu_konfirmasi" 
          ) {
            this.$router.push("/transaction");
          } else if (
            response.data.data.progress == "plan" &&
            response.data.data.progress_data != null
          ) {
            this.$router.push("/treatment-plan");
          } else {
            this.pd = response.data.data.progress_data
          }
      }) 
      .catch((error) => {
          if(error.response.data.message == "Unauthenticate"){
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    text  : 'Silahkan Login terlebih dahulu',
                    color  : 'error',
            })
            this.$router.push({name: 'Login'})
          }
      })
    },
    cancelOrder(){
            this.dialogConfirm3 = false
    },
     checkPlan(){
          this.$Progress.start();

          let config = {
            headers: {
              Authorization: "Bearer " + this.user.api_token,
            },
          };

          this.axios
            .post("/apiv1/order_received", {}, config)
            .then((response) => {
              this.dialogConfirm3 = false;
              this.$Progress.finish();
              this.setAlert({
                status: true,
                color: "success",
                text: response.data.message,
              });
              window.location.reload()
            })
            .catch((error) => {
              this.$Progress.finish();
              this.dialogConfirm3 = false;
              let { data } = error.response;
              if (typeof data.message === "string") {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: data.message,
                });
              } else {
                Object.values(data.message).forEach((value) => {
                  this.setAlert({
                    status: true,
                    color: "error",
                    text: value[0],
                  });
                });
              }
            });
      },
  },
  mounted() {
    this.statusProgress();
  },
  created(){
    try {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.$Progress.start();

              let config = {
                headers: {
                  Authorization: "Bearer " + this.user.api_token,
                },
              };

              let formData = {
                token_firebase: token,
              };
              this.axios
                .post("/apiv1/token_firebase", formData, config)
                .then(() => {
                  this.$Progress.finish();
                })
                .catch((error) => {
                  this.$Progress.finish();
                  let { data } = error.response;
                  if (typeof data.message === "string") {
                    this.setAlert({
                      status: true,
                      color: "error",
                      text: data.message,
                    });
                  } else {
                    Object.values(data.message).forEach((value) => {
                      this.setAlert({
                        status: true,
                        color: "error",
                        text: value[0],
                      });
                    });
                  }
                });
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    } catch (e) {
      console.log(e);
    }
  }
};
</script>

<style lang="stylus">
    @font-face {
        font-family: Montserrat;
        src: url('/fonts/Montserrat-Regular.ttf');
    }

    .txt-header{
      font-family: 'Montserrat-bold';
    }

    .txt-body{
      font-family: 'Montserrat';
    }
</style>