<template>
    <v-app>
        <div class="d-flex flex-column justify-space-between align-center pa-10">
            <v-row align="center" justify="space-around">
                <v-col v-if="isVerified" cols="12" class="d-flex flex-column align-center pt-10">
                    <lottie
                    :options="defaultOptions" 
                    :height="300" 
                    :width="300" />

                    <h1>Berhasil !</h1>
                    <p>Email anda berhasil di verifikasi</p>

                    <v-btn
                      style="text-transform: capitalize !important;"
                      x-large
                      to="/login"
                      class="mt-2 white--text"
                      color="indigo"
                    >
                      LOGIN NOW
                    </v-btn>
                </v-col>
                <v-col v-else cols="12" class="d-flex flex-column align-center pt-10">
                    <lottie2
                    :options="defaultOptions2" 
                    :height="300" 
                    :width="300" />

                    <h1>Gagal !</h1>
                    <p>Maaf email anda gagal di verifikasi</p>

                    <v-btn
                      style="text-transform: capitalize !important;"
                      x-large
                      to="/login"
                      class="mt-2 white--text"
                      color="indigo"
                    >
                      BACK TO LOGIN
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<script>
  import Lottie from 'vue-lottie';
  import Lottie2 from 'vue-lottie';
  import anim1 from '../assets/raw/success.json';
  import anim2 from '../assets/raw/failed.json';

  export default {
    name: 'VerifyAccount',
    components: {
      'lottie': Lottie,
      'lottie2': Lottie2
    },
    data() {
      return {
        isVerified: true,
        defaultOptions: { animationData: anim1 },
        defaultOptions2: { animationData: anim2 },
        animationSpeed: 1
      }
    },
    created() {
      this.$Progress.start()
      this.axios.get('/apiv1/verify/' + this.$route.params.token)
      .then(() => {
          this.$Progress.finish()
          this.isVerified = true
      })
      .catch(() => {
          this.$Progress.finish()
          this.isVerified = false
      })
    },
  }
</script>
