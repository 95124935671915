<template>
  <div>
      <v-container>

        <div class="row mt-20">
            <div class="col-8">
                <p style="font-size:10px;color:#949494;margin-bottom:0px;">Order ID #{{ history.invoice_order.order_id }}</p>
                <p style="font-size:10px;color:#949494;">Date: {{ history.invoice_order.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}</p>
            </div>
        </div>
        <p style="color:orange;" v-if="history.invoice_order.status == 'menunggu'">Menunggu Pembayaran</p>
        <p style="color:#a8ad00;" v-if="history.invoice_order.status == 'menunggu_konfirmasi'">Menunggu Konfirmasi</p>
        <p style="color:#04ced9;" v-if="history.invoice_order.status == 'dikonfirmasi'">Dikonfirmasi, paket akan segera dikirim</p>
        <p style="color:#a60074;" v-if="history.invoice_order.status == 'dikirim'">Pesanan anda telah dikirim</p>
        <p style="color:#07a60f;" v-if="history.invoice_order.status == 'selesai'">Selesai</p>
        <p style="color:red;" v-if="history.invoice_order.status == 'ditolak'">Pembayaran Ditolak dengan alasan : {{ history.invoice_order.message }} Silahkan upload ulang</p>

        <div>
            <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                Your shopping cart
                <p style="font-size:10px;color:#949494;">Below are your cart details</p>
            </span>
        </div>

        <div style="margin-top:-10px;">
            <v-card flat>
            <v-list three-line>
                <template v-for="(item, index) in history.invoice_order.order_detail" >
                    <v-list-item
                    :key="'cart'+index"
                    >
                    <v-list-item-avatar>
                        <v-img :src="getImage(item.produk.image)"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-html="item.produk.name"></v-list-item-title>
                        <v-list-item-subtitle>
                            Rp. {{ formatPrice(item.produk.price) }} 
                            <span style="float:right">
                            {{ item.qty }} Item
                            </span> 
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <v-container style="margin:0px;padding-left:0px;padding-right:0px;padding-top:0px;">
            <v-divider></v-divider>
            <v-card-actions style="font-weight:bold;">
                Total
                <v-spacer />
                Rp. {{ formatPrice(history.invoice_order.price) }}

            </v-card-actions>

            <v-divider></v-divider>
            
            </v-container>
            </v-card>
        </div>

    
        <div class="row">
            <div class="col-8">
                <span
                    class="font-weight-black"
                    style="color: #2b388f; font-size: 18px;margin-top:-10px;"
                >
                    Your aligner plan
                    <p style="font-size:10px;color:#949494;">Below are your aligner plan</p>
                </span>
            </div>
        </div>

        <div class="row" style="margin-top:0px;padding-top:0px;">
          <div class="col-6" v-for="(data, i) in history.treatment_detail" :key="i">
            <v-card>
              <v-img
                :src="getImage('images/treatment/' + data.treatment)"
                class="white--text"
                height="200px"
                width="100%"
                >
              </v-img>
            </v-card>
          </div>
        </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HistoryAlignerDetail',
  data() {
    return {
      history: [],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        carts: 'cart/carts',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        addCart: 'cart/add',
      }),
      transactionDetail(){
        this.$Progress.start();
        let { id } = this.$route.params
        let url = '/apiv1/history_aligner_detail/'+ id
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get(url, config)
          .then((response) => {
            this.$Progress.finish();
            this.history = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
            return error.response;
          });
      },
  },
  
  created() {
    this.transactionDetail();
  },

};
</script>

