<template>
  <div class="camera-modal">
    <v-app-bar app color="white" dark>
      <v-btn icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">LET US SEE YOUR TEETH</h4>
    </v-app-bar>

    <video ref="video" class="camera-stream" />

    <div class="camera-modal-container text-center">
      <v-btn class="mx-2" fab dark large color="pink" @click="capture">
        <v-icon dark> mdi-camera-iris </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaStream: null,
    };
  },
  mounted() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((mediaStream) => {
        this.mediaStream = mediaStream;
        this.$refs.video.srcObject = mediaStream;
        this.$refs.video.play();
      })
      .catch((error) => console.error("getUserMedia() error:", error));
  },
  methods: {
    capture() {
      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(mediaStreamTrack);
      return imageCapture.takePhoto().then((blob) => {
        console.log(blob);
      });
    },
  },
  destroyed() {
    const tracks = this.mediaStream.getTracks();
    tracks.map((track) => track.stop());
  },
};
</script>

<style>
/* @font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Bold.ttf");
}
.camera-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.camera-stream {
  width: 100%;
  max-height: 100%;
}
.camera-modal-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
} */
</style>