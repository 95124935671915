<template>
    <div class="pa-5">
    <v-app-bar app color="white" dark>
      <v-btn to="/profile" icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">CHANGE PASSWORD</h4>
    </v-app-bar>
        <v-row>
            <v-col
              cols="12" style="margin-top:20%;"
            >

               <div style="margin-top:-10px;">
                <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px;margin-top:-10px;"
                >
                  Keep your account confidential
                </span>
                <br />
                <span class="font-weight-light" style="font-size: 12px">
                  Please enter your new password in the field below
                </span>
              </div>

               <v-form ref="form" enctype="multipart/form-data" lazy-validation>

                  <v-text-field
                    style="margin-top:15px;"
                    v-model="password"
                    :rules="passwordRules"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hint="At least 6 characters"
                    required
                    counter
                    ></v-text-field>

                    <v-text-field
                    v-model="confirmPassword"
                    :rules="confirmPasswordRules"
                    label="Confirm Password"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hint="At least 6 characters"
                    counter
                    required
                    ></v-text-field>


                    <div class="text-xs-center">


                    <v-btn
                    style="text-transform: capitalize !important;"
                    block
                    x-large
                    class="mt-2 white--text"
                    color="indigo"
                    @click="save"
                    >
                    SAVE
                    </v-btn>
                    </div>
                </v-form>
            </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Profile',
   data () {
    return {
        showPassword: false,
        password: '',
        passwordRules: [
        v => !!v || 'Password required.',
        v => (v && v.length >= 6) || 'Min 6 characters',
        ],
        confirmPassword: '',
        confirmPasswordRules: [
          (value) => !!value || 'type confirm password',
          (value) =>
            value === this.password || 'The password confirmation does not match.',
        ],
    }
   },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      save(){
          if (this.$refs.form.validate()) {
            this.$Progress.start();
            let config = {
                headers: {
                    Authorization: "Bearer " + this.user.api_token,
                    'Content-Type': 'multipart/form-data'
                },
            };

            let formData = new FormData();

            formData.append("password", this.password);

            this.axios
            .post("/apiv1/change_password", formData, config)
            .then((response) => {
                this.$Progress.finish();
                this.setAlert({
                    status: true,
                    color: "success",
                    text: response.data.message,
                });
                this.$router.push("/profile");
            })
            .catch((error) => {
                this.$Progress.finish()
                let { data } = error.response
                if(typeof data.message === 'string'){
                this.setAlert({
                        status : true,
                        color  : 'error',
                        text  : data.message,
                })
                }else{
                Object.values(data.message).forEach(value => {

                    this.setAlert({
                        status : true,
                        color  : 'error',
                        text  : value[0],
                    })

                });
                }
            });
          }

    },
  },    

};
</script>

