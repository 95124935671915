<template>
    <div>
        <v-app-bar app color="white" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="black--text"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
                <v-img
                :src="require('../../assets/images/logo.png')"
                contain
                height="40"
                />
            <v-spacer></v-spacer>
            <v-btn target="_blank" href="https://api.whatsapp.com/send?phone=6281252055645&text=Halo%20Kak%2C%20ada%20yang%20mau%20saya%20konsultasikan%20nih%20!%20" icon style="color:black;">
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-app-bar>
        
        <vue-progress-bar></vue-progress-bar>


        <v-navigation-drawer app v-model="drawer">       
        <v-list-item v-if="!guest" class="pa-4">
            <v-list-item-avatar>
                    <img v-if="user.photo == null " :src="require('../../assets/images/profile.png')">
                    <img v-else :src="'https://freedomaligner.com/images/user/' + user.photo">
            </v-list-item-avatar>

            <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.phone_number }}</v-list-item-subtitle>
            <v-list-item-subtitle @click="logout()" style="color:red;margin-top:5px;">Logout</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>
            <template v-for="(item, index) in menus">
            <v-list-item
            :key="`menu-`+index"
            :to="item.route"
            v-if="!item.auth || (item.auth && !guest)"
            >
            <v-icon left>{{ item.icon }}</v-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </template>
        </v-list>
        
        </v-navigation-drawer>

        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>
            <v-slide-y-transition mode="out-in">
            <!-- If using vue-router -->
                <router-view></router-view>
            </v-slide-y-transition>
            </v-container>
        </v-main>

    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    menus: [
      { title: 'Home', icon: 'mdi-home', route: '/home' },
      { title: 'History Aligner', icon: 'mdi-history', route: '/history-aligner', auth: true },
      { title: 'Profile', icon: 'mdi-account', route: '/profile', auth: true },
    ],
  }),
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
      }),
      logout(){
        this.$Progress.start()
        let config = {
            headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
            },
        }
        this.axios.post('/apiv1/logout', {}, config)
        .then((response) => {
            this.$Progress.finish()
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                    status : true,
                    color  : 'success',
                    text  : response.data.message,
            })
            this.$router.push({name: 'Login'})
        })
        .catch((error) => {
            this.$Progress.finish()
            let { data } = error.response   
            this.setAlert({
                status : true,
                color  : 'error',
                text  : data.message,
            })
        })
      }
  },

};
</script>
