export default {
    namespaced: true,
    state: {
      progress  : "new",
      progress_data    : null
    },
    mutations: {
      set: (state, payload) => {
          state.progress  = payload.progress
          state.progress_data    = payload.progress_data
      },
    },
    actions: {
      set: ({commit}, payload) => {
          commit('set', payload)
      },
    },
    getters: {
      progress  : state => state.progress,
      progress_data   : state => state.progress_data
    }
} 