<template>
  <v-container>
    <v-row class="text-center pa-6">
      <v-col cols="12">
        <v-img
          :src="require('../assets/images/gambar1.png')"
          class="my-3"
          contain
          height="200"
        />

        <v-img
          :src="require('../assets/images/logo.png')"
          class="my-3 mt-8"
          contain
          height="65"
        />

        <p class="font-default mt-8">Let's get started</p>

        <p class="font-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit sit at orci velit nunc tellus imperdiet. Pretium ante eu, quam in euismod pulvinar elit consequat nec. Cursus velit dictum
        </p>

        <v-btn
          style="text-transform: capitalize !important;"
          block
          large
          depressed
          to="/register"
          color="indigo white--text"
        >
          Sign Up
        </v-btn>

        <v-btn
          style="text-transform: capitalize !important;"
          block
          large
          to="/login"
          class="mt-2"
          outlined
          color="indigo"
        >
          Sign In
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../assets/fonts/Poppins-Bold.ttf');
    }
    .font-default{
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: #2B388F;
      letter-spacing: -0.24px;
    }

    .font-description{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: #FAAF40;
    }
</style>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'HelloWorld',
    computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
      }),
    },
    created() {
      if(!this.guest){
        this.$router.push({ name: 'Home'})
      }else{
        this.$router.push({ name: 'Login'})
      }
    },
  }
</script>
