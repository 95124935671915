<template>
  <div>
      <v-container>

        <div class="row">
            <div class="col-8">
                        <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                My order
                <p style="font-size:10px;color:#949494;margin-bottom:0px;">Order ID #{{ transaction.order_id }}</p>
                <p style="font-size:10px;color:#949494;">Date: {{ transaction.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}</p>
            </span>
            </div>
        </div>
        <p style="color:orange;" v-if="transaction.status == 'menunggu'">Menunggu Pembayaran</p>
        <p style="color:#a8ad00;" v-if="transaction.status == 'menunggu_konfirmasi'">Menunggu Konfirmasi</p>
        <p style="color:#04ced9;" v-if="transaction.status == 'dikonfirmasi'">Dikonfirmasi, paket akan segera dikirim</p>
        <p style="color:#a60074;" v-if="transaction.status == 'dikirim'">Pesanan anda telah dikirim</p>
        <p style="color:#07a60f;" v-if="transaction.status == 'selesai'">Selesai</p>
        <p style="color:red;" v-if="transaction.status == 'ditolak'">Pembayaran Ditolak dengan alasan : {{ transaction.message }} Silahkan upload ulang</p>
        
        <div v-if="transaction.status == 'dikirim'">



          
          <div class="row" v-if="transaction.ekspedisi == 'jnt'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/jnt.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'jne'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/jne.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'pos'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/pos.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'tiki'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/tiki.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'sicepat'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/sicepat.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'pahala'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/pahala.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'wahana'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/wahana.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          <div class="row" v-if="transaction.ekspedisi == 'indah_logistik'">
            <div class="col-6">
            <p style="margin-bottom:0px;"> <v-icon>mdi-truck-fast</v-icon> 
            
            EKSPEDISI :  
            </p>
            </div>
            <div class="col-6" style="padding-left:0px;">
              <v-img
                src="../../assets/images/ekspedisi/indah_logistik.png"
                width="80"
              >
              </v-img>
            </div>
          </div>
          
          




            <div class="form-row form-row-layout" style="border: 1px dashed black;margin-top:5px;padding:5px;">
                <span>RESI : </span>
                <input disabled type="text" v-model="transaction.no_resi" ref="noresi" />
                <v-btn @click="copy" text style="padding:0px;min-width:0px;">
                  <v-icon
                  >
                    mdi-content-copy
                  </v-icon>
                </v-btn>
          </div>
        </div>

        <div class="row mt-2">
            <div class="col-8">
                        <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                Recipient address
                <p style="font-size:10px;color:#949494;">Below are your recipient's address</p>
            </span>
            </div>
            <div class="col-4" v-if="transaction.status == 'menunggu' || transaction.status == 'ditolak'">
              <v-btn style="font-size:8px;padding:0px;" text color="green" @click="dialogConfirm2=true">
                Change Address
              </v-btn>
            </div>
        </div>
        <p>{{ transaction.provinsi.nama_provinsi }}, {{ transaction.kota.nama_kota }}, Kecamatan {{ transaction.kecamatan.nama_kecamatan }}, {{ transaction.address }} ({{ transaction.kode_pos }})</p>
    </v-container>


    <div class="ml-3">
        <span
            class="font-weight-black"
            style="color: #2b388f; font-size: 18px;margin-top:-10px;"
        >
            Your shopping cart
            <p style="font-size:10px;color:#949494;">Below are your cart details</p>
        </span>
    </div>
    <div style="margin-top:-10px;">
        <v-card flat>
        <v-list three-line>
            <template v-for="(item, index) in transaction.order_detail" >
                <v-list-item
                :key="'cart'+index"
                >
                <v-list-item-avatar>
                    <v-img :src="getImage(item.produk.image)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-html="item.produk.name"></v-list-item-title>
                    <v-list-item-subtitle>
                        Rp. {{ formatPrice(item.produk.price) }} 
                        <span style="float:right">
                        {{ item.qty }} Item
                        </span> 
                    </v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-container>
        <v-divider></v-divider>
        <v-card-actions style="font-weight:bold;">
            Total
            <v-spacer />
            Rp. {{ formatPrice(transaction.price) }}

        </v-card-actions>

        <v-divider></v-divider>

        <div v-if="transaction.status == 'menunggu' || transaction.status == 'ditolak'">
          <div class="mt-5">
              <span
                  class="font-weight-black"
                  style="color: #2b388f; font-size: 18px;margin-top:-10px;"
              >
                  PAYMENT ACCOUNT
                  <p style="font-size:10px;color:#949494;">Transfer to one of the following account</p>
              </span>
          </div>
          <v-card flat>
          <v-container>
            <table style="border:none;border-style:none;">
                            <tr style="border:none;border-style:none;">
                                <td style="border:none;border-style:none;"><img :src="require('../../assets/images/bca.png')"></td>
                                <td style="border:none;border-style:none;padding-left:10px;">
                                <span
                                class="font-weight-black"
                                style="font-size: 18px;"
                            >
                                5085148718
                                <p style="font-size:10px;color:#949494;">Name of Bank Acccount <br>K LAKSITA PRATIWI A</p>
                            </span>
                                </td>
                            </tr>
                        </table>
          </v-container>
          </v-card>

          <v-btn block large @click="dialogConfirm=true" color="success">
              <v-icon class="mr-2">mdi-arrow-up-bold-box</v-icon> &nbsp;
              UPLOAD PROOF OF PAYMENT
          </v-btn>
        </div>

        <div class="mt-4" v-if="transaction.status == 'dikirim'">
            <p style="font-size:10px;margin-bottom:1px;margin-top:5px;">If your order has delivered, please confirm !</p>
            <v-btn @click="dialogConfirm3=true" block large color="warning">
                ORDER HAS BEEN RECEIVED
            </v-btn>
        </div>
        
        </v-container>
        </v-card>
    </div>

    

    <template>
        <v-layout row justify-center>
            <v-dialog v-model="dialogConfirm" persistent max-width="290">
            <v-card>
                <v-card-title style="font-size:14px;">UPLOAD PROOF OF PAYMENT!</v-card-title>
                  <div class="dropbox2">
                    <input type="file" @change="filesChange();"
                      accept="image/*" class="input-file2">
                      <p v-if="isInitial">
                        Select Your file <br> or click to browse
                      </p>
                      <p v-if="isSaving">
                        <img v-bind:src="uploadedFiles" style="width:100%;">
                      </p>
                  </div>
                <v-card-actions>
                    <v-btn color="warning" @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="upload"> <v-icon>mdi-upload</v-icon> Upload</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfirm2" persistent max-width="290">
            <v-card>
                <v-card-title>Change your address!</v-card-title>
                <div class="v-container ma-4" style="margin-bottom:0px !important;">
                  
                  <v-form ref="form" lazy-validation>

                    <v-select
                      v-model="provinsi_id"
                      :items="provinsi"
                      :rules="provinsiRules"
                      label="Provinsi"
                      item-text="nama_provinsi"
                      item-value="id"
                      auto-grow 
                      outlined
                      @change="getKota"
                    ></v-select>

                    <v-select
                      v-model="kota_id"
                      :items="kota"
                      :rules="kotaRules"
                      label="Kota"
                      item-text="nama_kota"
                      item-value="id"
                      @change="getKecamatan"
                      auto-grow 
                      outlined
                    ></v-select>

                    <v-select
                      v-model="kecamatan_id"
                      :items="kecamatan"
                      :rules="kecamatanRules"
                      label="Kecamatan"
                      item-text="nama_kecamatan"
                      item-value="id"
                      auto-grow 
                      outlined
                    ></v-select>

                    <v-text-field
                      v-model="postal_code"
                      label="Kode Pos"
                      :rules="postalCodeRules"
                      outlined
                    ></v-text-field>

                    <v-textarea
                        label="Alamat lengkap (Desa, Nama Jalan, No. Rumah)"
                        v-model="address"
                        :rules="addressRules"
                        auto-grow    
                        rows="3"
                        outlined
                    ></v-textarea>
                    
                    </v-form>

                </div>
                <v-card-actions>
                    <v-btn color="warning" @click="cancelAddress">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="uploadAddress"> Change</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfirm3" persistent max-width="290">
              <v-card>
                  <v-card-title>Confirmation ?</v-card-title>
                  <p class="ml-5">Has your order been received ?</p>
                  <v-card-actions>
                      <v-btn color="warning" @click="cancelOrder">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="checkPlan"> Yes</v-btn>
                  </v-card-actions>
              </v-card>
            </v-dialog>
        </v-layout>
    </template>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'Products',
  data() {
    return {
      transaction: {},
      dialogConfirm: false,
      dialogConfirm2: false,
      dialogConfirm3: false,
      newAddress: null,
      uploadedFiles: null,
      currentStatus: null,
      uploadError: null,
      formFields: {
          picture: null
      },
      address: "",
      postal_code: "",
      provinsi_id: null,
      kota_id: null,
      kecamatan_id: null,
      provinsi: [],
      kota: [],
      kecamatan: [],
      postalCodeRules: [
        v => !!v || 'Kode Pos is required',
      ],
      addressRules: [
        v => !!v || 'Alamat lengkap is required',
      ],
      provinsiRules: [
        v => !!v || 'Provinsi is required',
      ],
      kotaRules: [
        v => !!v || 'Kota is required',
      ],
      kecamatanRules: [
        v => !!v || 'Kecamatan is required',
      ],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        carts: 'cart/carts',
      }),
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        addCart: 'cart/add',
      }),
      getProvinsi(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_provinsi", config)
          .then((response) => {
            this.$Progress.finish();
            this.provinsi = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      getKota(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_kota/" + this.provinsi_id, config)
          .then((response) => {
            this.$Progress.finish();
            this.kota = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      getKecamatan(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_kecamatan/" + this.kota_id, config)
          .then((response) => {
            this.$Progress.finish();
            this.kecamatan = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      reset() {
          // reset form to initial state
          this.currentStatus = STATUS_INITIAL;
          this.formFields.picture = null;
          this.uploadError = null;
      },
      transactionDetail(){
        this.$Progress.start();
        let url = '/apiv1/transaction';
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get(url, config)
          .then((response) => {
            this.$Progress.finish();
            this.transaction = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
            return error.response;
          });
      },
      upload(){
          if (this.formFields.picture != null) {
          this.$Progress.start();

          let config = {
            headers: {
              Authorization: "Bearer " + this.user.api_token,
            },
          };
          let { id } = this.$route.params   
          let formData = new FormData();
          formData.append("picture", this.formFields.picture);
          formData.set("id", id);

          this.axios
            .post("/apiv1/upload_proof", formData, config)
            .then((response) => {
              this.dialogConfirm = false;
              this.$Progress.finish();
              this.setAlert({
                status: true,
                color: "success",
                text: response.data.message,
              });
              window.location.reload()
            })
            .catch((error) => {
              this.$Progress.finish();
              this.dialogConfirm = false;
              let { data } = error.response;
              if (typeof data.message === "string") {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: data.message,
                });
              } else {
                Object.values(data.message).forEach((value) => {
                  this.setAlert({
                    status: true,
                    color: "error",
                    text: value[0],
                  });
                });
              }
            });
        }else{
              this.setAlert({ 
                  status: true,
                  color: "error",
                  text: "File tidak boleh kosong",
              });
              this.dialogConfirm = false;

        }
      },
      uploadAddress(){
         if (this.$refs.form.validate()) {
          this.$Progress.start();

          let config = {
            headers: {
              Authorization: "Bearer " + this.user.api_token,
            },
          }; 
          let formData = new FormData();
          formData.set('address', this.address)
          formData.set('provinsi_id', this.provinsi_id)
          formData.set('kota_id', this.kota_id)
          formData.set('kecamatan_id', this.kecamatan_id)
          formData.set('kode_pos', this.postal_code)

          this.axios
            .post("/apiv1/change_address", formData, config)
            .then((response) => {
              this.dialogConfirm2 = false;
              this.$Progress.finish();
              this.setAlert({
                status: true,
                color: "success",
                text: response.data.message,
              });
              window.location.reload()
            })
            .catch((error) => {
              this.$Progress.finish();
              this.dialogConfirm2 = false;
              let { data } = error.response;
              if (typeof data.message === "string") {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: data.message,
                });
              } else {
                Object.values(data.message).forEach((value) => {
                  this.setAlert({
                    status: true,
                    color: "error",
                    text: value[0],
                  });
                });
              }
            });
        }
      },
      checkPlan(){
          this.$Progress.start();

          let config = {
            headers: {
              Authorization: "Bearer " + this.user.api_token,
            },
          };

          this.axios
            .post("/apiv1/order_received", {}, config)
            .then((response) => {
              this.dialogConfirm3 = false;
              this.$Progress.finish();
              this.setAlert({
                status: true,
                color: "success",
                text: response.data.message,
              });
              window.location.reload()
            })
            .catch((error) => {
              this.$Progress.finish();
              this.dialogConfirm3 = false;
              let { data } = error.response;
              if (typeof data.message === "string") {
                this.setAlert({
                  status: true,
                  color: "error",
                  text: data.message,
                });
              } else {
                Object.values(data.message).forEach((value) => {
                  this.setAlert({
                    status: true,
                    color: "error",
                    text: value[0],
                  });
                });
              }
            });
      },
      cancel(){
            this.dialogConfirm = false
      },
      cancelAddress(){
            this.dialogConfirm2 = false
      },
      cancelOrder(){
            this.dialogConfirm3 = false
      },
      filesChange() {
        // handle file changes
        this.formFields.picture = event.target.files[0];
        this.uploadedFiles = URL.createObjectURL(event.target.files[0]);
        this.currentStatus = STATUS_SAVING;
      },
      copy(){
        navigator.clipboard.writeText(this.transaction.no_resi);
        document.execCommand('copy');
        this.setAlert({
          status: true,
          color: "success",
          text: "Copied",
        });
      },
      statusProgress() {
        this.$Progress.start()
        let config = {
                  headers: {
                      'Authorization': 'Bearer ' + this.user.api_token,
                  },
              }
              
        this.axios.post('/apiv1/check_progress', {}, config)
        .then((response) => {
            this.$Progress.finish()
            if (response.data.data.progress == "new") {
              this.$router.push("/assessment") 
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "0"
            ) {
              this.$router.push("/assessment-waiting");
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "1"
            ) {
              this.$router.push("/summary");
            } else if (
              response.data.data.progress == "assessment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "2"
            ) {
              this.alert = true;
            } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
              this.$router.push("/appointment");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "menunggu"
            ) {
              this.$router.push("/appointment-waiting");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "ditolak"
            ) {
              this.$router.push("/appointment-rejected");
            } else if (
              response.data.data.progress == "appointment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "berhasil"
            ) {
              this.$router.push("/appointment-success");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data == null
            ) {
              this.$router.push("/payment");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "menunggu"
            ) {
              this.$router.push("/payment");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "berhasil"
            ) {
              this.$router.push("/payment-success");
            } else if (
              response.data.data.progress == "payment" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "ditolak"
            ) {
              this.$router.push("/payment-rejected");
            } else if (
              response.data.data.progress == "transaction" &&
              response.data.data.progress_data != null &&
              response.data.data.progress_data.status == "dikonfirmasi"
              || response.data.data.progress_data.status == "dikirim"
            ) {
              this.$router.push("/aligner-plan");
            } else if (
              response.data.data.progress == "plan" &&
              response.data.data.progress_data == null
            ) {
              this.$router.push("/aligner-plan");
            } else if (
              response.data.data.progress == "plan" &&
              response.data.data.progress_data != null
            ) {
              this.$router.push("/treatment-plan");
            }
        }) 
        .catch((error) => {
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            }
        })
      },
  },
  
  created() {
    this.transactionDetail();
    this.reset();
    this.statusProgress();
    this.getProvinsi();
  },

};
</script>


<style lang="stylus">
.dropbox2 {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: white;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file2 {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox2:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox2 p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
