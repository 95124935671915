<template>
  <div>
    <v-app-bar app color="white" dark>
      <v-btn to="/assessment" icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">LET US SEE YOUR BOTTOM TEETH</h4>
    </v-app-bar>

    <video ref="video" v-show="image == null" class="camera-stream" autoplay muted playsinline />
    <v-img
      :src="image"
      v-show="image != null"
      width="100%"
      class="camera-stream"
    ></v-img>

    <div class="water-mark">
      <div v-if="image == null">
        <v-img src="../../../assets/images/assessment/bottom-teeth.png" contain>
        </v-img>
      </div>
    </div>

    <div class="camera-modal-container text-center">
      <input @change="filesChange()" id="fileUpload" accept="image/*" type="file" hidden>
      <v-btn
        v-show="image == null"
        class="mx-2"
        fab
        dark
        large
        color="secondary"
        @click="chooseFiles()"
      >
        <v-icon dark> mdi-file-image </v-icon>
      </v-btn>
      <v-btn
        v-show="image == null"
        class="mx-2"
        fab
        dark
        large
        color="primary"
        @click="capture"
        :loading="loading"
      >
        <v-icon dark> mdi-camera-iris </v-icon>
      </v-btn>
      <v-btn
        @click="
          image = null;
          stopCam();
          loadCamera(cam);
        "
        class="mx-2"
        fab
        dark
        large
        color="secondary"
      >
        <v-icon>mdi-camera-flip</v-icon>
      </v-btn>
      <v-btn
        v-show="image !== null"
        @click="sendData"
        class="mx-2"
        fab
        dark
        large
        color="primary"
        :loading="loading"
      >
        <v-icon>mdi-cloud-upload</v-icon>
      </v-btn>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      mediaStream: null,
      image: null,
      loading: false,
      cam: null,
    };
  },
  mounted() {
    this.loadCamera("user");
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    chooseFiles() {
        document.getElementById("fileUpload").click()
    },
    filesChange() {
      const imagePick = event.target.files[0];
      const readerPick = new FileReader();
      readerPick.readAsDataURL(imagePick);
      readerPick.onload = e =>{
          this.image = e.target.result;
      };
    },
    stopCam() {
      //stop camera
      const tracks = this.mediaStream.getTracks();
      tracks.map((track) => track.stop());
    },
    async loadCamera(face) {
      // this.stopCam();
      if (face == "user") {
        this.cam = "environment";
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: face
          }
        })
        
        this.$refs.video.srcObject = this.mediaStream
        // navigator.mediaDevices
        //   .getUserMedia({ video: { facingMode: face } })
        //   .then((mediaStream) => {
        //     this.mediaStream = mediaStream;
        //     this.$refs.video.srcObject = mediaStream;
        //     this.$refs.video.play();
        //   })
        //   .catch((error) => console.error("getUserMedia() error:", error));
      } else if (face == "environment") {
        this.cam = "user";
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: { exact: "environment" }
          }
        })
        
        this.$refs.video.srcObject = this.mediaStream
        // navigator.mediaDevices
        //   .getUserMedia({ video: { facingMode: { exact: "environment" } } })
        //   .then((mediaStream) => {
        //     this.mediaStream = mediaStream;
        //     this.$refs.video.srcObject = mediaStream;
        //     this.$refs.video.play();
        //   })
        //   .catch((error) => console.error("getUserMedia() error:", error));
      }
    },
    capture() {
      if ('ImageCapture' in window) {

        const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
        const imageCapture = new window.ImageCapture(mediaStreamTrack);
        let reader = new FileReader();
        this.loading = true;
        imageCapture.takePhoto().then((blob) => {
          if (blob != null) {
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              this.image = reader.result;
              this.loading = false;
              //stop camera
              const tracks = this.mediaStream.getTracks();
              tracks.map((track) => track.stop());
            }.bind(this);
          }
        });
        
      } else {

        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.srcObject = this.mediaStream;

        return new Promise((resolve, reject) => {
          video.addEventListener('loadeddata', async () => {
            const { videoWidth, videoHeight } = video;
            canvas.width = videoWidth;
            canvas.height = videoHeight;
            try {
              await video.play();
              context.drawImage(video, 0, 0, videoWidth, videoHeight);
              canvas.toBlob(resolve, 'image/png');

              this.image = canvas.toDataURL();
              this.loading = false;
              //stop camera
              const tracks = this.mediaStream.getTracks();
              tracks.map((track) => track.stop());

            } catch (error) {
              reject(error);
            }
          });
        });

      }
    },
    sendData() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post(
          "/apiv1/assessment",
          {
            assessment: this.image,
            type: "bottom",
          },
          config
        )
        .then(() => {
          this.$router.push({
            name: "AssessmentWaiting",
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  destroyed() {
    const tracks = this.mediaStream.getTracks();
    tracks.map((track) => track.stop());
  },
};
</script>
