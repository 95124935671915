<template>
  <v-app>
    <v-banner
        v-if="deferredPrompt"
        color="info"
        dark
        class="text-left"
      >
        Get our free app. It won't take up space on your phone and also works offline!
        
        <template v-slot:actions>
          <v-btn text @click="dismiss">Dismiss</v-btn>
          <v-btn text @click="install">Install</v-btn>
        </template>
      </v-banner>
      <SplashScreen :isLoading="isLoading" />
      <main v-if="!isLoading">
        <alert/>
        <router-view/>
        <vue-progress-bar></vue-progress-bar>
      </main>
      
  </v-app>
</template>

<script>

import SplashScreen from "./views/SplashScreen.vue";

export default {
  name: 'App',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
    ]
  },
  components: {
      Alert: () => import('@/components/Alert.vue'),
      SplashScreen
  },
  data() {
    return { 
      isLoading: true,
      deferredPrompt: null
      };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  },


};
</script>
