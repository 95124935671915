export default {
    namespaced: true,
    state: {
      front  : 'sopek',
      side    : '', 
      top    : '',
      bottom : ''
    },
    mutations: {
      set: (state, payload) => {
          state.front  = payload.front
          state.side    = payload.side
          state.top    = payload.top
          state.bottom    = payload.bottom
      },
    },
    actions: {
      set: ({commit}, payload) => {
          commit('set', payload)
      },
    },
    getters: {
      front  : state => state.front,
      side   : state => state.side,
      top    : state => state.top,
      bottom    : state => state.bottom,
    }
} 