import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Profil from '../components/Profil.vue'
import VerifyAccount from '../views/VerifyAccount.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import PasswordReset from '../views/PasswordReset.vue'
// import Home from '../components/Home.vue'
import Appointment from '../components/appointment/Create.vue'
import Appointment_waiting from '../components/appointment/Waiting.vue'
import Appointment_rejected from '../components/appointment/Rejected.vue'
import Appointment_success from '../components/appointment/Success.vue'
import Payment from '../components/payment/Check.vue'
import Payment_success from '../components/payment/Success.vue'
import Payment_rejected from '../components/payment/Rejected.vue'
import Template from '../views/layout/Template.vue'
import store from '../store'
import CameraView  from '../components/CameraView.vue'
import Assessment  from '../components/assessment/Index.vue'
import AssessmentWaiting  from '../components/assessment/Waiting.vue'
import CameraFront  from '../components/assessment/camera/Front.vue'
import CameraSide  from '../components/assessment/camera/Side.vue'
import CameraTop  from '../components/assessment/camera/Top.vue'
import CameraBottom  from '../components/assessment/camera/Bottom.vue'
import Summary from '../components/assessment/Summary.vue';
import EditAccount from '../components/profile/EditAccount.vue';
import ChangePassword from '../components/profile/ChangePassword.vue';
import Products from '../components/products/List.vue';
import ProductsDetail from '../components/products/Detail.vue';
import Checkout from '../components/products/Checkout.vue';
import Transaction from '../components/products/Transaction.vue';
import Cart from '../components/products/Cart.vue';
import PaymentOrder from '../components/products/Payment.vue';
import AlignerPlanWaiting  from '../components/plan/Waiting.vue'
import TreatmentPlan  from '../components/plan/Treatment.vue'
import HistoryAligner  from '../components/history/Aligner.vue'
import HistoryAlignerDetail  from '../components/history/AlignerDetail.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/verify_account/:token',
    name: 'Verify_account',
    component: VerifyAccount
  },
  {
    path: '/forgot_password',
    name: 'Forgot_password',
    component: ForgotPassword
  },
  {
    path: '/password_reset/:token',
    name: 'Password_rest',
    component: PasswordReset
  },
  {
    path: '/',
    component: Template,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Assessment,
        meta: { auth: true }
      },
      {
        path: '/appointment',
        name: 'Appointment',
        component: Appointment,
        meta: { auth: true }
      },
      {
        path: '/appointment-waiting',
        name: 'Appointment-waiting',
        component: Appointment_waiting,
        meta: { auth: true }
      },
      {
        path: '/appointment-rejected',
        name: 'Appointment-rejected',
        component: Appointment_rejected,
        meta: { auth: true }
      },
      {
        path: '/appointment-success',
        name: 'Appointment-success',
        component: Appointment_success,
        meta: { auth: true }
      },
      {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        meta: { auth: true }
      },
      {
        path: '/payment-success',
        name: 'Payment-success',
        component: Payment_success,
        meta: { auth: true }
      },
      {
        path: '/payment-rejected',
        name: 'Payment-rejected',
        component: Payment_rejected,
        meta: { auth: true }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profil,
        meta: { auth: true }
      },
      {
        path: '/history-aligner',
        name: 'HistoryAligner',
        component: HistoryAligner,
        meta: { auth: true }
      },
      {
        path: '/history-aligner/:id',
        name: 'HistoryAlignerDetail',
        component: HistoryAlignerDetail,
        meta: { auth: true }
      },
      {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: { auth: true }
      },
      {
        path: '/transaction',
        name: 'Transaction',
        component: Transaction,
        meta: { auth: true }
      },
      {
        path: '/aligner-plan',
        name: 'AlignerPlan',
        component: AlignerPlanWaiting,
        meta: { auth: true }
      },
      {
        path: '/treatment-plan',
        name: 'TreatmentPlan',
        component: TreatmentPlan,
        meta: { auth: true }
      },
      //rofik => Start
      {
        path: '/assessment',
        name: 'Assessment',
        component: Assessment,
        meta: { auth: true }
      },{
        path: '/assessment-waiting',
        name: 'AssessmentWaiting',
        component: AssessmentWaiting,
        meta: { auth: true }
      },
      {
        path: '/summary',
        name: 'Summary',
        component: Summary,
        meta: { auth: true }
      },
      
      //rofik => end
    ]
  },
  // {
  //   path: '/transaction/:id',
  //   name: 'TransactionDetail',
  //   component: TransactionDetail,
  //   meta: { auth: true }
  // },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: { auth: true }
  },
  {
    path: '/payment-order',
    name: 'PaymentOrder',
    component: PaymentOrder,
    meta: { auth: true }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { auth: true }
  },
  {
    path: '/product/:id',
    name: 'ProductsDetail',
    component: ProductsDetail,
    meta: { auth: true }
  },

  {
    path: '/camera',
    name: 'Camera',
    component: CameraView,
    meta: { auth: true }
  },
  {
    path: '/camera-front',
    name: 'CameraFront',
    component: CameraFront,
    meta: { auth: true }
  },
  {
    path: '/camera-side',
    name: 'CameraSide',
    component: CameraSide,
    meta: { auth: true }
  },
  {
    path: '/camera-top',
    name: 'CameraTop',
    component: CameraTop,
    meta: { auth: true }
  },
  {
    path: '/camera-bottom',
    name: 'CameraBottom',
    component: CameraBottom,
    meta: { auth: true }
  },
  {
    path: '/edit-account',
    name: 'EditAccount',
    component: EditAccount,
    meta: { auth: true }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { auth: true }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if(store.getters['auth/guest']){
      // tampilkan pesan bahwa harus login dulu
      store.dispatch('alert/set', {
        status : true,
        text  : 'Silahkan Login terlebih dahulu',
        color  : 'error',
      })

      router.push('/login')
    }
    else{
      next()
    } 
  }
  else{
      next()
  }
})

export default router
