<template>
  <div>
    <v-app-bar app color="white" dark>
      <v-btn to="/products" icon>
        <v-icon class="black--text">mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="black--text">CHECKOUT</h4>
    </v-app-bar>

    <v-container class="mt-13" style="padding-bottom:0px;">

        <div>
            <span
                class="font-weight-black"
                style="color: #2b388f; font-size: 18px;margin-top:-10px;"
            >
                Recipient address
                <p style="font-size:10px;color:#949494;">Please enter the recipient's address</p>
            </span>
        </div>
        <v-form ref="form" lazy-validation>

        <v-select
          v-model="provinsi_id"
          :items="provinsi"
          :rules="provinsiRules"
          label="Provinsi"
          item-text="nama_provinsi"
          item-value="id"
          auto-grow 
          outlined
          @change="getKota"
        ></v-select>

        <v-select
          v-model="kota_id"
          :items="kota"
          :rules="kotaRules"
          label="Kota"
          item-text="nama_kota"
          item-value="id"
          @change="getKecamatan"
          auto-grow 
          outlined
        ></v-select>

        <v-select
          v-model="kecamatan_id"
          :items="kecamatan"
          :rules="kecamatanRules"
          label="Kecamatan"
          item-text="nama_kecamatan"
          item-value="id"
          auto-grow 
          outlined
        ></v-select>

        <v-text-field
          v-model="postal_code"
          label="Kode Pos"
          :rules="postalCodeRules"
          outlined
        ></v-text-field>

        <v-textarea
            label="Alamat lengkap (Desa, Nama Jalan, No. Rumah)"
            v-model="address"
            :rules="addressRules"
            auto-grow    
            rows="3"
            outlined
        ></v-textarea>
        
        </v-form>
    </v-container>

    <div class="ml-3">
        <span
            class="font-weight-black"
            style="color: #2b388f; font-size: 18px;margin-top:-10px;"
        >
            Your shopping cart
            <p style="font-size:10px;color:#949494;">Below are your cart details</p>
        </span>
    </div>
    <div style="margin-top:-10px;padding-bottom:60px;" v-if="countCart>0">
        <v-card flat>
        <v-list three-line v-if="countCart>0">
            <template v-for="(item, index) in carts" >
                <v-list-item
                :key="'cart'+index"
                >
                <v-list-item-avatar>
                    <v-img :src="getImage(item.image)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle>
                        Rp. {{ formatPrice(item.price) }} 
                        <span style="float:right">
                        {{ item.quantity }} Item
                        </span> 
                    </v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-container>
        <v-divider></v-divider>
        <v-card-actions style="font-weight:bold;">
            Total
            <v-spacer />
            Rp. {{ formatPrice(totalPrice) }}
        </v-card-actions>
        </v-container>
        </v-card>
    </div>

    <v-card class="bg_cart4 pa-3">
        <v-layout wrap>
            <v-flex xs12>
            <v-btn block large color="success" @click="dialogConfirm=true">
            <v-icon class="mr-2">mdi-beaker-check-outline</v-icon> &nbsp;
            ORDER NOW
            </v-btn>
            </v-flex>
        </v-layout>
    </v-card>

    <template>
        <v-layout row justify-center>
            <v-dialog v-model="dialogConfirm" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Confirmation!</v-card-title>
                <v-card-text>If You continue, transaction will be processed</v-card-text>
                <v-card-actions>
                    <v-btn color="warning" @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="pay">Continue</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-layout>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Checkout',
  data() {
    return {
      product: {},
      address: "",
      postal_code: "",
      provinsi_id: null,
      kota_id: null,
      kecamatan_id: null,
      provinsi: [],
      kota: [],
      kecamatan: [],
      dialogConfirm: false,
      postalCodeRules: [
        v => !!v || 'Kode Pos is required',
      ],
      addressRules: [
        v => !!v || 'Alamat lengkap is required',
      ],
      provinsiRules: [
        v => !!v || 'Provinsi is required',
      ],
      kotaRules: [
        v => !!v || 'Kota is required',
      ],
      kecamatanRules: [
        v => !!v || 'Kecamatan is required',
      ],
    };
  },
  computed: {
      ...mapGetters({
        user  : 'auth/user',
        guest  : 'auth/guest',
        carts: 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalQuantity : 'cart/totalQuantity',
      }),
  },
  methods: {
      ...mapActions({
        setAuth : 'auth/set',
        setAlert : 'alert/set',
        addCart: 'cart/add',
        setCart       : 'cart/set',
        setPayment    : 'setPayment'
      }),
      getProvinsi(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_provinsi", config)
          .then((response) => {
            this.$Progress.finish();
            this.provinsi = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      getKota(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_kota/" + this.provinsi_id, config)
          .then((response) => {
            this.$Progress.finish();
            this.kota = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      getKecamatan(){
        this.$Progress.start();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .get("/apiv1/get_kecamatan/" + this.kota_id, config)
          .then((response) => {
            this.$Progress.finish();
            this.kecamatan = response.data.data;
          })
          .catch((error) => {
            this.$Progress.finish();
            if(error.response.data.message == "Unauthenticate"){
              this.$Progress.finish()
              this.setAuth({}) // kosongkan auth ketika logout
              this.setAlert({
                      status : true,
                      text  : 'Silahkan Login terlebih dahulu',
                      color  : 'error',
              })
              this.$router.push({name: 'Login'})
            } 
            return error.response;
          });
      },
      pay(){
          if (this.$refs.form.validate()) {
              this.dialogConfirm = false
                this.$Progress.start();
                let safeCart = JSON.stringify(this.carts)
                let formData = new FormData()
                formData.set('address', this.address)
                formData.set('provinsi_id', this.provinsi_id)
                formData.set('kota_id', this.kota_id)
                formData.set('kecamatan_id', this.kecamatan_id)
                formData.set('kode_pos', this.postal_code)
                formData.set('carts', safeCart);
                formData.set('total_price', this.totalPrice);
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + this.user.api_token,
                    },
                }
                this.axios
                .post("/apiv1/checkout", formData, config)
                .then((response) => {
                    this.$Progress.finish();
                    this.setPayment(response.data.data)
                    this.setAlert({
                        status: true,
                        color: "success",
                        text: response.data.message,
                    });
                    this.$router.push({path: "/transaction"})
                    this.setCart([])
                })
                .catch((error) => {
                    this.$Progress.finish();
                    let { data } = error.response;
                    if (typeof data.message === "string") {
                    this.setAlert({
                        status: true,
                        color: "error",
                        text: data.message,
                    });
                    } else {
                    Object.values(data.message).forEach((value) => {
                        this.setAlert({
                        status: true,
                        color: "error",
                        text: value[0],
                        });
                    });
                    }
                });
          }else{
            this.dialogConfirm = false
          }            
        },
        cancel(){
            this.dialogConfirm = false
        },
        statusProgress() {
            this.$Progress.start()
            let config = {
                        headers: {
                            'Authorization': 'Bearer ' + this.user.api_token,
                        },
                    }
                    
            this.axios.post('/apiv1/check_progress', {}, config)
            .then((response) => {
                this.$Progress.finish()
                if (response.data.data.progress == "new") {
                    this.$router.push("/assessment") 
                } else if (
                    response.data.data.progress == "assessment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "0"
                ) {
                    this.$router.push("/assessment-waiting");
                } else if (
                    response.data.data.progress == "assessment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "1"
                ) {
                    this.$router.push("/summary");
                } else if (
                    response.data.data.progress == "assessment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "2"
                ) {
                    this.alert = true;
                } else if (response.data.data.progress == "appointment" && response.data.data.progress_data == null) {
                    this.$router.push("/appointment");
                } else if (
                    response.data.data.progress == "appointment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "menunggu"
                ) {
                    this.$router.push("/appointment-waiting");
                } else if (
                    response.data.data.progress == "appointment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "ditolak"
                ) {
                    this.$router.push("/appointment-rejected");
                } else if (
                    response.data.data.progress == "appointment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "berhasil"
                ) {
                    this.$router.push("/appointment-success");
                } else if (
                    response.data.data.progress == "payment" &&
                    response.data.data.progress_data == null
                ) {
                    this.$router.push("/payment");
                } else if (
                    response.data.data.progress == "payment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "menunggu"
                ) {
                    this.$router.push("/payment");
                } else if (
                    response.data.data.progress == "payment" &&
                    response.data.data.progress_data != null &&
                    response.data.data.progress_data.status == "berhasil"
                ) {
                    this.$router.push("/payment-success");
                }else if (
                    response.data.data.progress == "transaction"
                ) {
                    this.$router.push("/transaction");
                }
            }) 
            .catch((error) => {
                if(error.response.data.message == "Unauthenticate"){
                    this.$Progress.finish()
                    this.setAuth({}) // kosongkan auth ketika logout
                    this.setAlert({
                            status : true,
                            text  : 'Silahkan Login terlebih dahulu',
                            color  : 'error',
                    })
                    this.$router.push({name: 'Login'})
                }
            })
        },
    },
    created() {
        this.statusProgress();
        this.getProvinsi();
    },
};
</script>

<style lang="stylus">
  .bg_cart4{
        width : 100%;
        bottom : 0;
        z-index : 87;
        position : fixed;
    }
</style>
